import { useStore } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, message, Table, Typography } from "antd";
import { ApiOutlined, PlusOutlined } from "@ant-design/icons";

import httpClient from "../../common/httpClient";
import { truncate } from "lodash";

export interface ApiKeysProps {}

const { Title, Text, Link } = Typography;

const ApiKeys: React.FunctionComponent<ApiKeysProps> = () => {
  const store = useStore();
  const [apikeys, setApikeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  /**
   * @description fetch api keys from remote API
   */
  async function fetchApiKeys() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/user/apikey");

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setApikeys(data.apiKeys);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description generate new api key
   */
  async function generateAPIKey() {
    setLoading(true);
    try {
      const { data } = await httpClient.post("/user/apikey");

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("API key created.");
      await fetchApiKeys();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description handle block un block key
   * @param record api object
   */
  async function handleBlockUnKey(record: {
    apiKey: string;
    blocked: boolean;
  }) {
    const { apiKey, blocked } = record;
    try {
      let data: any = {};
      if (blocked) {
        const res = await httpClient.patch("/user/unblock-apikeys", {
          apiKeys: [apiKey],
        });
        data = res.data;
      } else {
        const res = await httpClient.patch("/user/block-apikeys", {
          apiKeys: [apiKey],
        });
        data = res.data;
      }

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await fetchApiKeys();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      key: "sn",
      title: "S/N",
      dataIndex: "sn",
      // width: "200px",
      render: (txt: string, r: any, index: number) => index + 1,
    },
    {
      key: "apiKey",
      title: "Api Key",
      dataIndex: "apiKey",
      render: (txt: string) => (
        <Text copyable={{ text: txt }}>{truncate(txt, { length: 24 })}</Text>
      ),
    },
    {
      key: "blocked",
      title: "Blocked",
      width: "200px",
      dataIndex: "blocked",
      render: (txt: boolean) => (txt ? "Yes" : "No"),
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (
        txt: any,
        record: {
          apiKey: string;
          blocked: boolean;
        }
      ) => (
        <Link onClick={() => handleBlockUnKey(record)}>
          {record.blocked ? "Unblock" : "Block"}
        </Link>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Title level={4}>
            API Keys <ApiOutlined />
          </Title>
          <Link
            href={`https://api.${process.env.REACT_APP_URL}/docs`}
            target="_blank"
          >
            See API Documentation
          </Link>
        </div>
        <div>
          <Button type="primary" loading={loading} onClick={generateAPIKey}>
            <PlusOutlined />{" "}
            {!!apikeys.length ? "Regenerate Key" : "Generate API Key"}
          </Button>
        </div>
      </div>
      <Table
        sticky
        size="small"
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={apikeys}
        scroll={{ x: true }}
        className="apikeys_table"
      />
    </>
  );
};

export default ApiKeys;
