import { Action } from "redux";

/**
 * @description User Details redux action types
 */
export enum UserDetailsTypes {
  SetUserDetails = "SET_USERDETAILS",
}

/**
 * @description user rights allowed
 */
export interface UserRights {
  history?: boolean;
  addFunds?: boolean;
  viewBalance?: boolean;
  servicePrice?: boolean;
}

/**
 * @description User Details object type
 */
export interface UserDetails {
  id: string;
  email: string;
  token?: string;
  credit: number;
  ips?: string[];
  username: string;
  blocked: boolean;
  promoCode: string;
  rights: UserRights;
  role: "Admin" | "Main" | "Sub";
  allowedPaymentMethods: string[];
}

/**
 * @description set User Details redux action interface
 */
export interface UserDetailsAction extends Action {
  payload: UserDetails;
}

/**
 * @description set app User Details redux action
 * @param configs Global app configs object
 */
export function setUserDetails(userDetails: UserDetails) {
  return {
    payload: userDetails,
    type: UserDetailsTypes.SetUserDetails,
  } as UserDetailsAction;
}
