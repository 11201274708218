import { Table, message } from "antd";
import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { FunctionComponent, useEffect, useState } from "react";

import "./AdminProvider.scss";
import httpClient from "../../../common/httpClient";

interface AdminProvidersProps {}

const columns: ColumnsType = [
  {
    key: "title",
    title: "Title",
    dataIndex: "title",
  },
  {
    key: "url",
    title: "URL",
    dataIndex: "url",
  },
  {
    key: "type",
    title: "Type",
    dataIndex: "type",
  },
  {
    key: "limit",
    title: "Limit",
    dataIndex: "limit",
  },
  {
    key: "delay",
    title: "Delay",
    dataIndex: "delay",
    render: (v: number) => `${v ?? 0}ms`
  },
];

const AdminProviders: FunctionComponent<AdminProvidersProps> = () => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    fetchProviders();
  }, []);

  /**
   * @description fetch subusers from remote api
   */
  async function fetchProviders() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/provider");

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }
      setProviders(data);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Table
      sticky
      size="small"
      loading={loading}
      pagination={false}
      scroll={{ x: true }}
      columns={columns as any}
      dataSource={providers}
      className="admin_providers_table"
    />
  );
};

export default AdminProviders;
