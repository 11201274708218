import { ColumnsType } from "antd/lib/table";
import { FunctionComponent, useState } from "react";
import { Button, Col, message, Row, Select, Table } from "antd";

import httpClient from "../../../common/httpClient";
import { useListManagedGlobalServices } from "../../../hooks/managed-services";

interface GlobalActionsProps {}

const GlobalActions: FunctionComponent<GlobalActionsProps> = () => {
  const [loading, setLoading] = useState(false);
  const [disabledActions, setDisabledActions] = useState<any>({});
  const { data, isLoading, isSuccess, refetch } =
    useListManagedGlobalServices();

  async function clearDiscountedServices(id: number) {
    setLoading(true);
    try {
      const { data } = await httpClient.delete(
        "/service/management/global/discount",
        { id }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await refetch();
      message.success("Service discount cleared from all users");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function addDisabledServices(id: number) {
    setLoading(true);
    try {
      const { data } = await httpClient.post(
        "/service/management/global/disabled",
        { id }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await refetch();
      message.success("Service disabled from all users");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function clearDisabledServices(id: number) {
    setLoading(true);
    try {
      const { data } = await httpClient.delete(
        "/service/management/global/disabled",
        { id }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await refetch();
      message.success("Service enabled for all users");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function handleDisabledServicesAction(id: number) {
    switch (disabledActions[id]) {
      case "Enable":
        await clearDisabledServices(id);
        break;
      case "Disable":
        await addDisabledServices(id);
        break;
      default:
        return;
    }
  }

  const columns: ColumnsType = [
    {
      width: "100px",
      key: "title",
      title: "Title",
      dataIndex: "title",
    },
    {
      width: "200px",
      key: "enable_disable",
      title: "Disable Actions",
      render: (r) => (
        <Row gutter={8} style={{ width: "200px" }}>
          <Col>
            <Select
              allowClear
              size="small"
              style={{ width: "100px" }}
              value={disabledActions[r.id] || undefined}
              onChange={(v) =>
                setDisabledActions({ ...disabledActions, [r.id]: v })
              }
              options={[
                { title: "Enable", value: "Enable" },
                { title: "Disable", value: "Disable" },
              ]}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={() => handleDisabledServicesAction(r.id)}
            >
              Apply
            </Button>
          </Col>
        </Row>
      ),
    },
    {
      width: "200px",
      key: "clear_discount",
      title: "Discount",
      render: (r) => (
        <Button
          danger
          size="small"
          type="primary"
          onClick={() => clearDiscountedServices(r.id)}
        >
          Clear Discount
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table
        sticky
        size="small"
        pagination={false}
        scroll={{ x: true }}
        columns={columns as any}
        loading={isLoading || loading}
        className="admin_managed_services_table"
        dataSource={isSuccess ? data!.services : undefined}
      />
    </>
  );
};

export default GlobalActions;
