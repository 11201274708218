import { BehaviorSubject } from "rxjs";

export const subUserSubject = new BehaviorSubject(null);

export interface SubUserModel {
  id: string;
  ips: string[];
  email: string;
  created: string;
  blocked: boolean;
  modified: string;
  username: string;
  commission: number;
  rights: SubUserPermissions;
}

export interface SubUserPermissions {
  history: boolean;
  // addFunds: boolean;
  viewBalance: boolean;
  servicePrice: boolean;
}

export interface SubUserForm {
  ips: string[];
  email: string;
  blocked: boolean;
  username: string;
  rights: string[];
  password?: string;
}

export function initValues(user?: SubUserModel): any {
  if (user) {
    const _rights = [];
    const {  history, servicePrice, viewBalance } = user.rights; 

    if (history) _rights.push("history");
    // if (addFunds) _rights.push("addFunds");
    if (viewBalance) _rights.push("viewBalance");
    if (servicePrice) _rights.push("servicePrice");

    return { ...user, rights:  _rights };
  }

  return {
    ips: [],
    email: "",
    username: "",
    password: "",
    blocked: false,
    rights: ["history", "viewBalance", "servicePrice"],
  };
}
