import { Rule } from "antd/lib/form";

export interface RegisterFormData {
  email: string;
  phone: string;
  code?: string;
  country?: string;
  company?: string;
  lastName: string;
  username: string;
  password: string;
  recaptcha: string;
  firstName: string;
  promoCode?: string;
}

export const initRegisterValues: RegisterFormData = {
  email: "",
  username: "",
  password: "",
  recaptcha: "",
  firstName: "",
  lastName: "",
  phone: "",
  code: undefined,
  country: undefined,
  promoCode: undefined,
};

export const rules: { [key: string]: Rule[] } = {
  firstName: [
    {
      required: true,
      message: "First name required.",
    },
  ],
  lastName: [
    {
      required: true,
      message: "Last name required.",
    },
  ],
  phone: [
    {
      required: true,
      message: "Phone number required.",
    },
  ],
  country: [
    {
      required: true,
      message: "Country is required.",
    },
  ],
  company: [{ required: false }],
  email: [
    { type: "email", message: "Invalid email." },
    { required: true, message: "Email is required." },
  ],
  username: [
    { required: true, message: "Please input your Username!" },
    { whitespace: false, message: "No whitespace allowed." },
  ],
  password: [
    { required: true, message: "Please input your Password!" },
    { min: 5, max: 50, message: "Password must be 5-50 characters." },
  ],
  promoCode: [{ required: false }],
};
