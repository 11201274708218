import {
  UserOutlined,
  UserSwitchOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import React from "react";
import { Space, Tabs } from "antd";

import ApiKeys from "./ApiKeys";
import Invoices from "./Invoice";
import Analytics from "./Analytics";
import UpdateUser from "./UpdateUser";
import PasswordReset from "./ResetPassword";
import ManagedServices from "./ManagedServices";
import ManualRebate from "./ManualRebate";
// import DisableService from "./DisabledService";
// import DiscountService from "./DiscountService";
// import ClearDiscountService from "./ClearDiscountService";
// import ClearDisabledService from "./ClearDisabledService";

const { TabPane } = Tabs;

export interface UsersActionProps {
  user?: any;
}

const UsersAction: React.FunctionComponent<UsersActionProps> = ({ user }) => {
  return (
    <Tabs defaultActiveKey="0">
      <TabPane
        tab={
          <span>
            <UserOutlined />
            Users
          </span>
        }
        key="0"
      >
        <UpdateUser userInfo={user!} />
      </TabPane>
      <TabPane
        key="1"
        tab={
          <span>
            <UserSwitchOutlined />
            Action
          </span>
        }
      >
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            paddingRight: "10px",
            minHeight: "calc(100vh - 190px)",
            maxHeight: "calc(100vh - 190px)",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Analytics username={user.username} />
            <PasswordReset username={user.username} />
            <Invoices username={user.username} />
            <ManualRebate username={user.username} />
            {/* <DiscountService username={user.username} />
            <DisableService username={user.username} /> */}
            <ApiKeys username={user.username} />
            {/* <ClearDisabledService username={user.username} />
            <ClearDiscountService username={user.username} /> */}
          </Space>
        </div>
      </TabPane>
      <TabPane
        key="2"
        tab={
          <span>
            <OrderedListOutlined />
            Services
          </span>
        }
      >
        <ManagedServices user={user} />
      </TabPane>
    </Tabs>
  );
};

export default UsersAction;
