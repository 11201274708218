import { sortBy } from "lodash";
import { BehaviorSubject } from "rxjs";

export interface ServiceGroupModel {
  id: number;
  title: string;
}

export interface SelServiceModel {
  providerServiceId: number;
  priority: number;
  apiProvider: ApiProviderModel;
  distributionRatio: number;
}

export interface ApiProviderModel {
  id: number;
  title: string;
}

export interface ServiceModel {
  id: number;
  time: string;
  title: string;
  price: number;
  limit?: number;
  manual: boolean;
  featured: boolean;
  disabled: boolean;
  description: string;
  group: ServiceGroupModel;
  selSvc: SelServiceModel[];
}

export interface ProviderService {
  priority: number;
  serviceId: number;
  providerId: number;
  distributionRatio: number;
}

export interface ServiecForm {
  time: string;
  price: number;
  title: string;
  limit?: number;
  manual: boolean;
  groupId?: number;
  disabled: boolean;
  featured: boolean;
  description: string;
  selSvc?: ProviderService[];
}

export const initValues = (init?: ServiceModel): ServiecForm => ({
  time: init?.time ?? "",
  groupId: init?.group.id,
  title: init?.title ?? "",
  price: init?.price ?? 0.1,
  limit: init?.limit,
  manual: init?.manual ?? false,
  disabled: init?.disabled ?? false,
  featured: init?.featured ?? false,
  description: init?.description ?? "",
  selSvc: init?.selSvc
    ? sortBy(
        init?.selSvc.map((selS) => ({
          priority: selS.priority,
          distributionRatio: selS.distributionRatio,
          providerId: selS.apiProvider.id,
          serviceId: selS.providerServiceId,
        })),
        ["priority"]
      )
    : undefined,
});

export const serviceSubject = new BehaviorSubject(null);
