import { Row, Col } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import "./Batch.scss";
import BatchList from "./BatchList";
import OrdersPreview from "./OrdersPreview";

export interface BatchProps {
  sessionToken: string;
}

const Batch: React.FunctionComponent<BatchProps> = () => {
  const [previewBatch, setPreviewBatch] = useState<any>(-1);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <>
      {isMobile && (
        <Row>
          <Col flex="auto" className="batch_order_col">
            <BatchList setPreviewBatch={setPreviewBatch} />
            <OrdersPreview placeable preview={previewBatch} />
          </Col>
        </Row>
      )}
      {!isMobile && (
        <Row>
          <Col flex="180px">
            <BatchList setPreviewBatch={setPreviewBatch} />
          </Col>
          <Col flex="auto" className="batch_order_col">
            <OrdersPreview placeable preview={previewBatch} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Batch;
