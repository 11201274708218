import { sortBy } from "lodash";
import { List, message } from "antd";
import { useStore } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { batchSubject } from "./batch.utils";
import httpClient from "../../common/httpClient";

const { Item } = List;
const { Meta } = Item;

export interface BatchListProps {
  setPreviewBatch: (batchId: any) => void;
}

const BatchList: React.FunctionComponent<BatchListProps> = ({
  setPreviewBatch,
}) => {
  const store = useStore();
  const [initial, setInitial] = useState(true);
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState<any[]>([]);

  /**
   * @description Pagination batches effect
   */
  useEffect(() => {
    if (initial) setInitial(false);
    fetchBatches();

    // Refresh batch list on order placed
    const unsub = batchSubject.subscribe(async () => {
      await fetchBatches();
    });

    return () => {
      unsub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description fetch batches from remote api
   */
  async function fetchBatches() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const from = new Date();
      from.setHours(new Date().getHours() - 12);

      const { data } = await httpClient.get("/batch", {
        page: 0,
        size: 50,
        opened: true,
        status: "Completed",
        from: from.toISOString(),
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      const { data: pData } = await httpClient.get("/batch", {
        page: 0,
        size: 50,
        status: "Pending",
      });

      if (pData.statusCode === 500 || pData.error) {
        switch (typeof pData.message) {
          case "object":
            pData.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(pData.message);
            return;
        }
      }

      const _batches = sortBy(
        [...data.batches, ...pData.batches],
        (b) => b.id
      ).reverse();

      setBatches(_batches);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function closeBatch(id: number) {
    setLoading(true);
    try {
      const { data } = await httpClient.post("/batch/close", {
        id,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
      await fetchBatches();
    }
  }

  return (
    <div onDoubleClick={fetchBatches}>
      <List
        size="small"
        loading={loading}
        dataSource={batches}
        className="batch_list"
        renderItem={(batch: any) => (
          <Item
            key={batch.id}
            className="batch_list_item"
            actions={[
              batch.status === "Completed" ? (
                <CloseOutlined onClick={() => closeBatch(batch.id)} />
              ) : null,
            ]}
            onClick={() => setPreviewBatch(batch)}
          >
            <Meta
              description={"Batch ID: " + batch.id}
              title={batch.title + `(${batch.id})`}
            />
          </Item>
        )}
      />
    </div>
  );
};

export default BatchList;
