import React, { useState } from "react";
import { KeyOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";

import httpClient from "../../../common/httpClient";

export interface ResetPasswordProps {
  username: string;
}

const { Title } = Typography;

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({
  username,
}) => {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values: any) {
    const { repassword, newPassword } = values;
    if (repassword !== newPassword) {
      message.error("Password not matched.");
      return;
    }

    setLoading(true);
    try {
      const { data } = await httpClient.post(
        `/user/admin/reset-password/${username}`,
        {
          newPassword: values.newPassword,
        }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Password reset.");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Reset Password <KeyOutlined />
      </Title>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item
              name="newPassword"
              label="New Password"
              tooltip="New Password"
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="repassword"
              label="Retype Password"
              tooltip="Retype Password"
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button type="primary" loading={loading} htmlType="submit">
            Update
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ResetPassword;
