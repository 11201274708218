import fs from "fs";

function registerBinaryFiles(ctx: any) {
  ctx.keys().forEach((key: any) => {
    // extracts "./" from beginning of the key
    fs.writeFileSync(key.substring(2), ctx(key));
  });
}

function registerAFMFonts(ctx: any) {
  ctx.keys().forEach((key: any) => {
    const match = key.match(/([^/]*\.afm$)/);
    if (match) {
      // afm files must be stored on data path
      fs.writeFileSync(`data/${match[0]}`, ctx(key).default);
    }
  });
}

// register all files found in assets folder (relative to src)
// @ts-ignore
registerBinaryFiles(require.context("../assets", true));

// register AFM fonts distributed with pdfkit
// is good practice to register only required fonts to avoid the bundle size increase
// @ts-ignore
registerAFMFonts(require.context("pdfkit/js/data", false, /Helvetica.*\.afm$/));
