// import Reaptcha from "reaptcha";
import { Dispatch } from "redux";
import React, { useState } from "react";
import { connect, batch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Image, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import "./Login.scss";
import * as form from "./form";
import httpClient from "../../common/httpClient";
import { setConfigs } from "../../actions/configs.action";
import { UserDetails, setUserDetails } from "../../actions/userdetails.action";

export interface LoginProps {
  theme: "light" | "dark";
  loginUser: (userDetails: UserDetails) => void;
}

const Login: React.FunctionComponent<LoginProps> = ({ theme, loginUser }) => {
  const history = useHistory();
  // const [captcha, setCaptcha] = useState<string>();
  const [loading, setLoading] = useState(false);

  async function loginHandler(values: form.LoginFormData) {
    setLoading(true);
    try {
      // values.recaptcha = captcha!;
      const { data, status } = await httpClient.post("/login", values);

      if (status !== 200 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      loginUser(data);
      localStorage.setItem("session-key", data.token);
      history.replace("/");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="main_wrapper">
      <div className="inner_wrapper">
        <div className="logo-wrapper">
          <Image
            alt="Logo"
            width={150}
            preview={false}
            className="logo"
            src={
              process.env.PUBLIC_URL +
              `/assets/logo.0.${theme}.png`
            }
          />
        </div>

        <Form
          name="login"
          onFinish={loginHandler}
          initialValues={form.initLoginValues}
        >
          <Form.Item name="username" rules={form.rules.username}>
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>

          <Form.Item name="password" rules={form.rules.password}>
            <Input.Password placeholder="Password" prefix={<LockOutlined />} />
          </Form.Item>

          {/* <Form.Item name="recaptcha">
            <Reaptcha
              theme={theme}
              onVerify={setCaptcha}
              sitekey="6Ld0MQ4fAAAAAELCDsUpu968y7_bPVps-rLoYRu_"
            />
          </Form.Item> */}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Log in
            </Button>
            &nbsp; Or <Link to="/register">register now!</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const theme = state.configs.theme;
  return {
    theme,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    loginUser: (userDetails: UserDetails) =>
      batch(() => {
        dispatch(setUserDetails(userDetails));
        dispatch(setConfigs({ sessionToken: userDetails.token }));
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
