import {
  Col,
  Row,
  Form,
  Input,
  Switch,
  Button,
  message,
  Checkbox,
  Typography,
} from "antd";
import React, { useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  initValues,
  SubUserForm,
  SubUserModel,
  subUserSubject,
  SubUserPermissions,
} from "./subuser.utils";
import httpClient from "../../common/httpClient";

export interface CreateSubUserProps {
  show: boolean;
  subUser?: SubUserModel;
  setShow: (show: boolean) => void;
}

const { Text } = Typography;

const options = [
  { label: "History", value: "history" },
  // { label: "Add Funds", value: "addFunds" },
  { label: "View Balance", value: "viewBalance" },
  { label: "Service Price", value: "servicePrice" },
];

const CreateSubUser: React.FunctionComponent<CreateSubUserProps> = ({
  show,
  subUser,
  setShow,
}) => {
  const [form] = Form.useForm<SubUserForm>();
  const [loading, setLoading] = useState(false);

  /**
   * @description create new sub user
   * @param userData user data
   */
  async function createSubUser(userData: any) {
    try {
      const { data } = await httpClient.post("/subuser", userData);

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("User created.");
      subUserSubject.next(null);
      setShow(false);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  /**
   * @param userData user data
   * @description update existing sub user
   */
  async function updateSubUser(userData: any) {
    try {
      const { data } = await httpClient.patch("/subuser", {
        ...userData,
        subUserId: subUser!.id,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("User updated.");
      subUserSubject.next(null);
      setShow(false);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  /**
   * @param values form values
   * @description form submit handler
   */
  async function handleFormSubmit(values: SubUserForm) {
    setLoading(true);
    const _rights = values.rights;

    const rights: SubUserPermissions = {
      history: _rights.includes("history"),
      // addFunds: _rights.includes("addFunds"),
      viewBalance: _rights.includes("viewBalance"),
      servicePrice: _rights.includes("servicePrice"),
    };

    values.rights = rights as any;

    if (subUser) {
      await updateSubUser(values);
    } else {
      await createSubUser(values);
    }
    setLoading(false);
  }

  return (
    <>
      <Form
        form={form}
        size="middle"
        layout="vertical"
        requiredMark={false}
        onFinish={handleFormSubmit}
        initialValues={initValues(subUser)}
      >
        <Row gutter={[10, 0]} style={{ marginBottom: "-10px" }}>
          <Col span={10}>
            <Form.Item name="username" label="Username" tooltip="Sub Username">
              <Input placeholder="user123" />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item name="email" label="Email" tooltip="Sub user email.">
              <Input placeholder="user123@domain.com" />
            </Form.Item>
          </Col>
        </Row>
        {!!!subUser && (
          <Row style={{ marginBottom: "-10px" }}>
            <Col span={24}>
              <Form.Item
                name="password"
                label="Password"
                tooltip="Password for sub user."
              >
                <Input placeholder="xxxxxx" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row style={{ marginBottom: "-10px" }}>
          <Form.Item
            name="rights"
            label="User Rights"
            tooltip="Allowed actions for this user."
          >
            <Checkbox.Group options={options} />
          </Form.Item>
        </Row>

        <Row style={{ marginBottom: "-10px" }}>
          <Col span={24}>
            <Text>Allowd IP(s)</Text>
            <Form.List name="ips">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <div className="required_ips_list">
                    {fields.map((field, index) => (
                      <Form.Item required key={field.key}>
                        <Row>
                          <Col span={22}>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message: "IP is required.",
                                },
                                {
                                  message: "Invalid IPv4",
                                  pattern: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                style={{ width: "100%" }}
                                placeholder="Enter IPv4 address"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ textAlign: "right" }}>
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      style={{ width: "100%" }}
                      onClick={() => {
                        add("", fields.length);
                      }}
                    >
                      Add IP
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "22%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Text>Block user</Text>
            </div>
            <div>
              <Form.Item noStyle name="blocked" valuePropName="checked">
                <Switch size="small" />
              </Form.Item>
            </div>
          </div>
          <div>
            <Button type="primary" htmlType="submit" loading={loading}>
              {subUser ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateSubUser;
