import React from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Image } from "antd";

import HeaderPop from "./HeaderPop";
import { UserDetails } from "../../actions/userdetails.action";

export interface BaseHeaderProps {
  theme?: "light" | "dark";
  userDetails?: UserDetails;
}

const BaseHeader: React.FunctionComponent<BaseHeaderProps> = ({
  theme,
  userDetails,
}) => {
  return (
    <>
      <div className="base_header_wrapper">
        <Image
          alt="Logo"
          width={70}
          preview={false}
          src={process.env.PUBLIC_URL + `/assets/logo.0.${theme}.png`}
        />
        <div>
          <Popover trigger="click" placement="topRight" content={<HeaderPop />}>
            <Avatar className="header_avatar">
              {!!userDetails?.username
                ? userDetails.username[0].toUpperCase()
                : "I"}
            </Avatar>
          </Popover>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  const userDetails = state.userDetails;
  const theme = state.configs.theme;
  return { userDetails, theme };
}

export default connect(mapStateToProps, null)(BaseHeader);
