import {
  Input,
  Modal,
  Table,
  Badge,
  Button,
  Tooltip,
  message,
  Typography,
  Pagination,
  Descriptions,
} from "antd";
import { useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";

import ServiceAction from "./ServiceAction";
import httpClient from "../../../common/httpClient";
import { ServiceModel, serviceSubject } from "./admin.utils";

export interface ServicesProps {}

const { Link } = Typography;

const Services: React.FunctionComponent<ServicesProps> = () => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState();
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [editService, setEditService] = useState<ServiceModel>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    if (init) setInit(false);
    fetchServices();

    const unsub = serviceSubject.subscribe(async () => {
      await fetchServices();
    });

    return () => {
      unsub.unsubscribe();
    };
  }, [page, size]);

  /**
   * @description fetch subusers from remote api
   */
  async function fetchServices() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/service", {
        size,
        filter,
        admin: true,
        page: page - 1,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      const _services: any[] = data.services.map((s: any, index: number) => {
        s["key"] = index;
        return s;
      });

      setTotal(data.total);
      setServices(_services);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  /**
   * @description open edit service dialog
   * @param record Service object
   */
  function handleEditService(record: ServiceModel) {
    setEditService(record);
    setShowCreate(true);
  }

  const columns = [
    {
      key: "id",
      title: "ID",
      width: "80px",
      dataIndex: "id",
      sorter: {
        compare: (a: any, b: any, ord: any) => {
          switch (ord) {
            case "ascend":
              return a.id - b.id;
            case "descend":
              return a.id - b.id;
            default:
              return a.id - b.id;
          }
        },
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: {
        compare: (a: any, b: any, ord: any) => {
          switch (ord) {
            case "ascend":
              return (
                a.title.toUpperCase().charCodeAt(0) -
                b.title.toUpperCase().charCodeAt(0)
              );
            case "descend":
              return (
                a.title.toUpperCase().charCodeAt(0) -
                b.title.toUpperCase().charCodeAt(0)
              );
            default:
              return (
                a.title.toUpperCase().charCodeAt(0) -
                b.title.toUpperCase().charCodeAt(0)
              );
          }
        },
      },
    },
    { width: "65px", title: "Price", dataIndex: "price", key: "price" },
    // { title: "Description", dataIndex: "description", key: "description" },
    { width: "100px", title: "Time", dataIndex: "time", key: "time" },
    { width: "100px", title: "Limit", dataIndex: "limit", key: "limit" },
    {
      width: "100px",
      key: "manual",
      title: "Processing",
      dataIndex: "manual",
      render: (txt: boolean) => (txt ? "Manual" : "Auto"),
    },
    {
      width: "100px",
      key: "disabled",
      title: "Status",
      dataIndex: "disabled",
      render: (txt: boolean) => (txt ? "Disabled" : "Available"),
    },
    {
      width: "100px",
      key: "featured",
      title: "Featured",
      dataIndex: "featured",
      render: (txt: boolean) => (txt ? "Yes" : "No"),
    },
    {
      key: "group",
      title: "Group",
      dataIndex: "group",
      render: (txt: any) => txt.title,
    },
    {
      width: "80px",
      title: "Providers",
      key: "selectedServices",
      dataIndex: "selSvc",
      render: (txt: any[]) => (txt ? txt.length : "-"),
    },
    {
      key: "id",
      width: "80px",
      title: "Action",
      render: (txt: any, record: any) => (
        <Link onClick={() => handleEditService(record)}>Edit</Link>
      ),
    },
  ];

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setEditService(undefined);
          setShowCreate(true);
        }}
        style={{ width: "100%", marginBottom: "10px" }}
      >
        Add Service
      </Button>

      <div
        style={
          !isMobile
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }
            : {}
        }
      >
        <div style={{ width: isMobile ? "100%" : "50%" }}>
          <Descriptions
            bordered
            size="small"
            column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label={<Badge text="Total" color="blue" />}>
              {total}
            </Descriptions.Item>
          </Descriptions>
        </div>

        <div style={isMobile ? { width: "100%" } : { width: "340px" }}>
          <div className="order_filter_wrapper">
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "8%" }}>
                <Tooltip title="Refresh">
                  <Button
                    type="link"
                    loading={loading}
                    onClick={fetchServices}
                    icon={<SyncOutlined />}
                    style={{ width: "100%" }}
                  />
                </Tooltip>
              </div>

              <div style={{ width: "60%" }}>
                <Input
                  size="small"
                  value={filter}
                  disabled={!!!total}
                  placeholder="Search Service"
                  style={{ width: isMobile ? "100%" : "200px" }}
                  onChange={(s) => setFilter(s.target.value as any)}
                />
              </div>

              <div style={{ width: "30%" }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={fetchServices}
                  style={{ width: "100%" }}
                >
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Table
        sticky
        size="small"
        loading={loading}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        dataSource={services}
        className="admin_services_table"
      />
      <div className="pagination">
        <Pagination
          size="small"
          total={total}
          current={page}
          pageSize={size}
          showSizeChanger
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>

      <Modal
        centered
        footer={null}
        destroyOnClose
        maskClosable={false}
        visible={showCreate}
        title={editService ? "Update Service" : "Create Service"}
        onCancel={() => {
          setEditService(undefined);
          setShowCreate(false);
        }}
        width="800px"
      >
        <ServiceAction
          setShow={setShowCreate}
          refresh={fetchServices}
          editService={editService}
        />
      </Modal>
    </>
  );
};

export default Services;
