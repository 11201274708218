import {
  Table,
  Badge,
  Space,
  Input,
  Button,
  Tooltip,
  message,
  DatePicker,
  Typography,
  Pagination,
  Descriptions,
} from "antd";
import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useMediaQuery } from "react-responsive";
import { SyncOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import "./LoginLogs.scss";
import httpClient from "../../../common/httpClient";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export interface LoginLogsProps {}

const LoginLogs: React.FunctionComponent<LoginLogsProps> = () => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(200);

  const [loading, setLoading] = useState(false);
  // const [selRows, setSelRows] = useState<any[]>([]);
  const [onSearchHover, setSearchHover] = useState(false);
  const [users, setUsers] = useState<string | undefined>();
  const [logs, setLogs] = useState<
    {
      id: number;
      ip: string;
      msg: string;
      success: boolean;
      username: string;
    }[]
  >([]);

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const [toDate, setToDate] = useState<string | null>();
  const [fromDate, setFromDate] = useState<string | null>();
  const [toDateM, setToDateM] = useState<moment.Moment | null>();
  const [fromDateM, setFromDateM] = useState<moment.Moment | null>();

  const columns: ColumnsType<any> = [
    {
      key: "id",
      title: "S\\N",
      dataIndex: "id",
    },
    {
      key: "ip",
      title: "IP",
      dataIndex: "ip",
    },
    {
      key: "user",
      title: "User",
      dataIndex: "username",
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (txt: string) =>
        new Date(txt).toLocaleString("en-US", {
          timeZone: "America/Virgin",
        }),
    },
    {
      key: "msg",
      title: "Message",
      dataIndex: "msg",
    },
  ];

  /**
   * @description handle search effect
   */
  useEffect(() => {
    setLoading(true);
    fetchlogs().finally(() => {
      setLoading(false);
    });
  }, [page, size]);

  /**
   * @description fetch orders from remote api
   */
  async function fetchlogs() {
    if (!!!store.getState().configs.sessionToken) return;
    // setSelRows([]);

    try {
      const { data } = await httpClient.get("/login-logs", {
        size,
        page: page - 1,
        users: users
          ?.split("\n")
          .map((s) => s.trim())
          .filter((s) => !!s),
        to: toDateM ? new Date(toDate!).toISOString() : null,
        from: fromDateM ? new Date(fromDate!)?.toISOString() : null,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      const _logs = data.logs.map((_log: any, index: number) => {
        _log["key"] = index;
        return _log;
      });

      setLogs(_logs);
      setTotal(data.total);
    } catch (err) {
      console.error(err);
      message.error("Something went wrong.");
    }
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  function handleDateChange(
    date: [moment.Moment, moment.Moment],
    dateString: [string, string]
  ) {
    if (date) {
      setToDate(dateString[1]);
      setFromDate(dateString[0]);
      setToDateM(date[1]);
      setFromDateM(date[0]);
    } else {
      setToDate(null);
      setFromDate(null);
      setToDateM(null);
      setFromDateM(null);
    }
  }

  return (
    <>
      <div className="bach_preview_head_title_wrapper">
        <div>
          <Title level={5}>Login Logs</Title>
        </div>
        <div>
          <Tooltip title="Refresh">
            <Button
              type="link"
              disabled={!!!total}
              onClick={async () => {
                setLoading(true);
                fetchlogs().finally(() => setLoading(false));
              }}
              icon={<SyncOutlined spin={loading} />}
            />
          </Tooltip>
        </div>
      </div>

      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label={<Badge text="Total" status="success" />}>
          {total}
        </Descriptions.Item>
      </Descriptions>

      <div className="login_logs_filter_wrapper">
        <div
          style={
            !isMobile
              ? { position: "absolute", zIndex: 100 }
              : { width: "100%", marginBottom: "10px" }
          }
        >
          <Input.TextArea
            size="small"
            value={users}
            placeholder="Search usernames"
            className="imei_search_text_area"
            onMouseOver={() => setSearchHover(true)}
            onMouseLeave={() => setSearchHover(false)}
            onChange={(e) => setUsers(e.target.value as any)}
            style={{
              width: isMobile ? "100%" : "180px",
              minHeight: onSearchHover ? "100px" : "25px",
              maxHeight: onSearchHover ? "100px" : "25px",
            }}
          />
        </div>

        <Space
          direction={isMobile ? "vertical" : "horizontal"}
          style={{
            width: "100%",
            marginLeft: !isMobile ? "190px" : undefined,
          }}
        >
          <RangePicker
            allowClear
            size="small"
            allowEmpty={[true, true]}
            style={{ width: isMobile ? "100%" : 210 }}
            onChange={handleDateChange as any}
            value={[fromDateM ?? null, toDateM ?? null]}
          />

          <Button
            size="small"
            type="primary"
            onClick={fetchlogs}
            style={{ width: "100%" }}
          >
            Filter
          </Button>
        </Space>
      </div>

      <Table
        sticky
        size="small"
        loading={loading}
        dataSource={logs}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: true }}
        columns={columns}
        className={"order_table_history"}
        // rowSelection={{
        //   selectedRowKeys: selRows.map((row) => row.key),
        //   onChange: (keys, selRowss) => setSelRows(selRowss),
        // }}
      />
      <div className="pagination">
        <Pagination
          size="small"
          total={total}
          current={page}
          pageSize={size}
          showSizeChanger
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>
    </>
  );
};

export default LoginLogs;
