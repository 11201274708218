import { Redirect, Route, Switch } from "react-router-dom";

import Init from "./Components/Init";
import Base from "./Containers/Base/Base";
import Login from "./Containers/Login/Login";
import Register from "./Containers/Register/Register";

export interface RoutesProps {}

const Routes: React.FunctionComponent<RoutesProps> = () => {
  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/session" component={Init} />
        <Route exact path="/register" component={Register} />
        <Route path="/" component={Base} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default Routes;
