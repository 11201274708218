import {
  Space,
  Table,
  Badge,
  Modal,
  Input,
  Button,
  Tooltip,
  message,
  Divider,
  Typography,
  Pagination,
  Descriptions,
} from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";

import "./ServiceGroups.scss";
import ServiceGroupForm from "./ServiceGroupModal";
import ServicePriceUpdate from "./ServicesPriceUpdate";
import httpClient from "../../../../common/httpClient";

export interface ServiceGroupsProps {}

const { Link } = Typography;

const ServiceGroups: React.FunctionComponent<ServiceGroupsProps> = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState();
  const [init, setInit] = useState(true);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editGroup, setEditGroup] = useState<any>();
  const [showCreate, setShowCreate] = useState(false);
  const [showPriceUpdate, setShowPriceUpdate] = useState(false);

  useEffect(() => {
    if (init) setInit(false);
    fetchGroups();
  }, [page, size]);

  /**
   * @description fetch groups
   * @param search search string
   */
  async function fetchGroups() {
    setLoading(true);
    try {
      const { data } = await httpClient.get("/service/group", {
        filter,
        page: 0,
        size: 10,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setTotal(data.total);
      setGroups(data.groups);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  function handleEditGroup(record: any) {
    setEditGroup(record);
    setShowCreate(true);
  }

  function handleEditPriceGroup(record: any) {
    setEditGroup(record);
    setShowPriceUpdate(true);
  }

  const columns = [
    { key: "id", title: "ID", dataIndex: "id" },
    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (txt: any, record: any) => (
        <Space>
          <Link onClick={() => handleEditGroup(record)}>Edit</Link>
          <Divider orientation="center" type="vertical" />
          <Link onClick={() => handleEditPriceGroup(record)}>Update Price</Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setShowCreate(true);
          setEditGroup(undefined);
        }}
        style={{ width: "100%", marginBottom: "10px" }}
      >
        Add Group
      </Button>
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label={<Badge text="Total" color="blue" />}>
          {total}
        </Descriptions.Item>
      </Descriptions>

      <div className="order_filter_wrapper">
        <div>
          <Space>
            <Input
              allowClear
              size="small"
              value={filter}
              disabled={!!!total}
              style={{ width: "200px" }}
              placeholder="Search Service"
              onChange={(s) => setFilter(s.target.value as any)}
            />

            <Button size="small" type="primary" onClick={fetchGroups}>
              Filter
            </Button>

            <Tooltip title="Refresh">
              <Button
                type="link"
                loading={loading}
                onClick={fetchGroups}
                icon={<SyncOutlined />}
              />
            </Tooltip>
          </Space>
        </div>
      </div>
      <Table
        sticky
        size="small"
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={groups}
        className="admin_services_table"
      />
      <div className="pagination">
        <Pagination
          size="small"
          total={total}
          current={page}
          pageSize={size}
          showSizeChanger
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>

      <Modal
        centered
        footer={null}
        destroyOnClose
        visible={showCreate}
        title={editGroup ? "Update Group" : "Create Group"}
        onCancel={() => {
          setEditGroup(undefined);
          setShowCreate(false);
        }}
      >
        <ServiceGroupForm
          editGroup={editGroup}
          refresh={fetchGroups}
          setShow={setShowCreate}
        />
      </Modal>

      <Modal
        centered
        width={400}
        footer={null}
        destroyOnClose
        visible={showPriceUpdate}
        title="Update group price"
        onCancel={() => {
          setEditGroup(undefined);
          setShowPriceUpdate(false);
        }}
      >
        <ServicePriceUpdate
          groupId={editGroup?.id}
          setShow={setShowPriceUpdate}
        />
      </Modal>
    </>
  );
};

export default ServiceGroups;
