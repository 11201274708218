import {
  Col,
  Row,
  Form,
  Input,
  Button,
  message,
  Typography,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";

export interface ManualRebateProps {
  username: string;
}

const { Title } = Typography;

const ManualRebate: React.FunctionComponent<ManualRebateProps> = ({
  username,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values: any) {
    values.username = username;
    setLoading(true);

    try {
      const { data } = await httpClient.post(
        "payment/admin/rebate-manual",
        values
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success(`Rebate successfull, New balance ${data.newBalance}`);
      form.resetFields();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Manual Rebate <FileTextOutlined />
      </Title>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{ instant: false, status: "Unpaid" }}
        onFinish={handleSubmit}
      >
        <Row gutter={[10, 10]}>
          <Col span={11}>
            <Form.Item
              name="amount"
              label="Amount"
              tooltip="Amount to rebate from user."
              rules={[{ required: true, message: "Amount is required." }]}
            >
              <InputNumber style={{ width: "100%" }} min={1} />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              name="note"
              label="Note"
              tooltip="Rebate note."
              rules={[{ required: true, message: "Note is required." }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button danger type="primary" htmlType="submit" loading={loading}>
            Rebate
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ManualRebate;
