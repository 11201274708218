import {
  Col,
  Row,
  Form,
  Select,
  Button,
  message,
  Divider,
  Skeleton,
  Typography,
  InputNumber,
} from "antd";
import { useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { CreditCardOutlined, MoneyCollectOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";

export interface PaymentManagerProps {}

const { Title } = Typography;

const PaymentManager: React.FunctionComponent<PaymentManagerProps> = () => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState<any>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    getSettings();
  }, []);

  async function updateSettings(values: any) {
    setLoading(true);
    try {
      const { data } = await httpClient.post("/settings", values);

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      getSettings();
      message.success("Settings updated");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function getSettings() {
    if (!!!store.getState().configs.sessionToken) return;
    try {
      const { data } = await httpClient.get("/settings");

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setInitValues(data);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  async function updateUsersPaymentMethod(values: {
    action: string;
    method: string;
  }) {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.patch(
        "/payment/admin/update-users-paymentmethod",
        values
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success(`Updated ${data.total || 0} Users`);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        padding: "40px",
        overflowY: "auto",
        minHeight: "calc(100vh - 100px)",
        maxHeight: "calc(100vh - 100px)",
      }}
    >
      <Title level={4}>
        Payment Manager <MoneyCollectOutlined />
      </Title>

      <Divider />

      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={updateUsersPaymentMethod}
      >
        <div>
          <Title level={5}>
            Bulk Payment Actions <CreditCardOutlined />
          </Title>
          <Row>
            <Col span={isMobile ? 24 : 12}>
              <Row gutter={[10, 10]} align="bottom">
                <Col span={12}>
                  <Form.Item
                    name="action"
                    label="Action"
                    rules={[{ required: true, message: "Action is required." }]}
                  >
                    <Select
                      disabled={loading}
                      allowClear
                      placeholder="Select Action"
                    >
                      <Select.Option value="Add">Add</Select.Option>
                      <Select.Option value="Remove">Remove</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="method"
                    label="Payment method"
                    rules={[
                      {
                        required: true,
                        message: "Payment method required.",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      disabled={loading}
                      placeholder="Select Payment Method."
                    >
                      <Select.Option value="Paypal">Paypal</Select.Option>
                      <Select.Option value="Stripe">Stripe</Select.Option>
                      <Select.Option value="Coinbase">Coinbase</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={6}>
                </Col> */}
                <Button
                  // size="large"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Update
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>

      <Divider />

      {initValues ? (
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={updateSettings}
          initialValues={initValues}
        >
          <Row>
            <Col span={isMobile ? 24 : 12}>
              <Row gutter={[10, 10]}>
                <Col span={8}>
                  <Form.Item
                    name="coinbaseTax"
                    label="Coinbase Tax"
                    tooltip="Coinbase Tax."
                    rules={[
                      {
                        required: true,
                        message: "Coinbase tax is required.",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="paypalTax"
                    label="Paypal Tax"
                    tooltip="Paypal Tax."
                    rules={[
                      { required: true, message: "Paypal tax is required." },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="stripeTax"
                    label="Stripe Tax"
                    tooltip="Stripe Tax."
                    rules={[
                      { required: true, message: "Stripe tax is required." },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
};

export default PaymentManager;
