import React, { useState } from "react";
import { KeyOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";

import httpClient from "../../common/httpClient";

export interface PasswordUpdateProps {}

const { Title } = Typography;

const PasswordUpdate: React.FunctionComponent<PasswordUpdateProps> = () => {
  const [form] = Form.useForm<any>();
  const [loading, setLoading] = useState(false);

  async function handleResetPassword(values: any) {
    const { reNewPassword, newPassword, oldPassword } = values;
    if (reNewPassword !== newPassword) {
      message.error("Password not match.");
      return;
    }

    setLoading(true);
    try {
      const { data } = await httpClient.post("/user/reset-password", {
        oldPassword,
        newPassword,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Password updated.");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Update Password <KeyOutlined />
      </Title>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleResetPassword}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="oldPassword"
              label="Enter Old Password"
              tooltip="Enter Old Password"
              rules={[
                { type: "string", message: "Invalid Password." },
                { min: 5, message: "Minimum 5 characters required." },
                { required: true, message: "Old Password is required." },
                { max: 50, message: "Maximum 50 characters required." },
              ]}
            >
              <Input.Password placeholder="xxxxxx" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item
              name="newPassword"
              label="Enter password"
              tooltip="New password"
              rules={[
                { type: "string", message: "Invalid New Password." },
                { required: true, message: "New Password is required." },
                { min: 5, message: "Minimum 5 characters required." },
                { max: 50, message: "Maximum 50 characters required." },
              ]}
            >
              <Input.Password placeholder="xxxxxx" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reNewPassword"
              label="Retype New Password"
              tooltip="Retype New password"
              rules={[
                { type: "string", message: "Invalid Password." },
                { min: 5, message: "Minimum 5 characters required." },
                { max: 50, message: "Maximum 50 characters required." },
                {
                  required: true,
                  message: "Retype Password is required.",
                },
              ]}
            >
              <Input.Password placeholder="xxxxxx" />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PasswordUpdate;
