import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Switch,
  Button,
  message,
  Checkbox,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import {
  initValues,
  UserInfoForm,
  UserInfoModel,
  PriceGroupModel,
  UserRightsModel,
  usersListSubject,
} from "./user.utils";
import httpClient from "../../../common/httpClient";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export interface UpdateUserProps {
  userInfo: UserInfoModel;
}

const options = [
  { label: "History", value: "history" },
  // { label: "Add Funds", value: "addFunds" },
  { label: "View Balance", value: "viewBalance" },
  { label: "Service Price", value: "servicePrice" },
];

const UpdateUser: React.FunctionComponent<UpdateUserProps> = ({
  // setShow,
  userInfo,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cancelSearch, setCancelSearch] = useState<any>();
  const [groups, setGroups] = useState<PriceGroupModel[] | undefined>(
    userInfo.priceGroup ? [userInfo.priceGroup] : undefined
  );
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  /**
   * @description fetch groups
   * @param search search string
   */
  async function fetchPriceGroups(search?: string) {
    try {
      const { data } = await httpClient.get("/user/price-group", {
        page: 0,
        size: 10,
        filter: search,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setGroups(data.groups);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  /**
   * @description search handler
   * @param search search string
   */
  function searchHandler(search: string) {
    if (cancelSearch) clearTimeout(cancelSearch);

    const timeOut = setTimeout(() => {
      fetchPriceGroups(search);
    }, 500);

    setCancelSearch(timeOut);
  }

  async function handleSubmit(values: UserInfoForm) {
    console.log(values);
    setLoading(true);
    const _rights = values.rights;

    const rights: UserRightsModel = {
      history: _rights.includes("history"),
      addFunds: _rights.includes("addFunds"),
      viewBalance: _rights.includes("viewBalance"),
      servicePrice: _rights.includes("servicePrice"),
    };

    values.rights = rights as any;
    try {
      const { data } = await httpClient.patch(
        `/user/admin/${userInfo.username}`,
        values
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("User updated.");
      usersListSubject.next(null);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initValues(userInfo)}
      >
        <Row gutter={[10, 10]} style={{ marginBottom: "-10px" }}>
          <Col span={8}>
            <Form.Item
              name="username"
              label="Username"
              tooltip="Username of user."
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item name="email" label="Email" tooltip="User email.">
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="credit" label="Credit" tooltip="User credit.">
              <InputNumber disabled style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={isMobile ? 5 : 2}>
            <Form.Item
              label="Unlink"
              name="unlinked"
              valuePropName="checked"
              tooltip="Unlink affiliated users from this user."
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 5 : 2}>
            <Form.Item
              label="Block"
              name="blocked"
              tooltip="Block user."
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col span={isMobile ? 5 : 3}>
            <Form.Item
              name="approved"
              label="Approve"
              valuePropName="checked"
              tooltip="Approve this user ."
              rules={[{ type: "boolean", message: "Invalid approve value." }]}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col span={isMobile ? 9 : 3}>
            <Form.Item
              name="commission"
              label="Commission"
              tooltip="Commission per affiliate order."
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>

          <Col span={isMobile ? 24 : 6}>
            <Form.Item
              name="priceGroup"
              label="Price Group"
              tooltip="Discount service based on attached price group."
            >
              <Select
                showSearch
                allowClear
                filterOption={false}
                onSearch={searchHandler}
                placeholder="Type to search"
                notFoundContent="No Groups Found."
              >
                {groups?.map((each: PriceGroupModel) => (
                  <Select.Option key={each.id} value={each.id}>
                    {each.title} - Discount: {each.discount}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!isMobile && (
            <Col span={8}>
              <Form.Item
                name="allowedPaymentMethods"
                label="Allowed Payment methods"
                tooltip="Allowed payment methods for user."
              >
                <Select
                  showSearch
                  mode="multiple"
                  showArrow={false}
                  placeholder="Select payment methods."
                >
                  <Select.Option value="Stripe">Stripe</Select.Option>
                  <Select.Option value="Paypal">Paypal</Select.Option>
                  <Select.Option value="Coinbase">Coinbase</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        {isMobile && (
          <Row>
            <Col span={24}>
              <Form.Item
                name="allowedPaymentMethods"
                label="Allowed Payment methods"
                tooltip="Allowed payment methods for user."
              >
                <Select
                  showSearch
                  mode="multiple"
                  showArrow={false}
                  placeholder="Select payment methods."
                >
                  <Select.Option value="Stripe">Stripe</Select.Option>
                  <Select.Option value="Paypal">Paypal</Select.Option>
                  <Select.Option value="Coinbase">Coinbase</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Form.Item
            name="rights"
            label="User Rights"
            tooltip="Only applies if user role is 'Sub'"
          >
            <Checkbox.Group options={options} />
          </Form.Item>
        </Row>

        <Row>
          <Col span={24}>
            <Form.List name="ips">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <div className="required_ips_list">
                    {fields.map((field, index) => (
                      <Form.Item required key={field.key}>
                        <Row>
                          <Col span={22}>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message: "IP is required.",
                                },
                                {
                                  message: "Invalid IPv4",
                                  pattern:
                                    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                style={{ width: "100%" }}
                                placeholder="Enter IPv4 address"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={2} style={{ textAlign: "right" }}>
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      style={{ width: "100%" }}
                      onClick={() => {
                        add("", fields.length);
                      }}
                    >
                      Add IP
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <div style={{ textAlign: "right" }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            Update
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdateUser;
