import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import React, { FormEvent, useState } from "react";
import { message, Typography, Button, Space } from "antd";

const { Title } = Typography;

export interface StripePayProps {
  closeDialog: () => void;
}

const StripePay: React.FunctionComponent<StripePayProps> = ({
  closeDialog,
}) => {
  const stripe = useStripe();
  const element = useElements();
  const [loading, setLoading] = useState(false);

  async function handleStripePurchase(event: FormEvent) {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !element) {
      message.error("Failed to make stripe payment.");
      setLoading(false);
      return;
    }

    // const cardElement = element!.getElement(CardElement);

    // const { paymentIntent } = await stripe.confirmCardPayment(clientSceret, {
    //   payment_method: { card: cardElement! as any },
    // });

    // setLoading(false);

    // if (paymentIntent?.status === "succeeded") {
    //   message.success("Payment successfull.");
    //   closeDialog();
    // }

    const paymentIntentResult = await stripe.confirmPayment({
      elements: element,
      confirmParams: {
        return_url: `http://app.${process.env.REACT_APP_URL}/`,
      },
      redirect: "if_required",
    });

    if (paymentIntentResult.error?.message) {
      message.error(paymentIntentResult.error.message);
      return;
    }

    if (paymentIntentResult.paymentIntent?.status === "succeeded") {
      message.success("Payment successful");
      closeDialog();
    }
  }

  return (
    <>
      <form onSubmit={handleStripePurchase}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <div>
            <Title level={5}>Enter Card Details:</Title>
          </div>
          <div>
            <PaymentElement
              options={{
                business: { name: "Imeilookup" },
                fields: { billingDetails: { address: "auto", name: "auto" } },
              }}
              id="payment-element"
            />
          </div>
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button type="primary" loading={loading} htmlType="submit">
              Pay Now
            </Button>
          </div>
        </Space>
      </form>
    </>
  );
};

export default StripePay;
