import {
  Row,
  Col,
  Form,
  Modal,
  Space,
  Input,
  Badge,
  Switch,
  Button,
  Upload,
  message,
  Typography,
  Progress,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";

import { batchSubject } from "./batch.utils";
import httpClient from "../../common/httpClient";
import SelService from "../../common/Components/SelectService";
import { rules, initVaules, OrderFormValues } from "./placeorder.form";
import axios from "axios";

const { Paragraph } = Typography;

export interface PlaceOrderProps {
  show: boolean;
  setShow: (show?: boolean) => void;
}

const PlaceOrder: React.FunctionComponent<PlaceOrderProps> = ({
  show,
  setShow,
}) => {
  const [total, setTotal] = useState(0);
  const [sample, setSample] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<OrderFormValues>();
  const [services, setService] = useState<any[]>([]);
  const [showSample, setShowSample] = useState(false);
  const [serviceId, setServiceId] = useState<number>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    fetchServices();
  }, []);

  async function fetchServices() {
    try {
      const { data } = await httpClient.get("/service", {
        page: 0,
        size: 1000,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setService(data.services);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  async function handleSubmit(values: OrderFormValues) {
    setLoading(true);

    if (!serviceId) {
      message.error("Please select a service.");
      setLoading(false);
      return;
    }

    const { batch, notify } = values;
    const imeis = values.imeis
      .split("\n")
      .map((imei) => imei.trim())
      .filter((imei) => !!imei);

    try {
      const { data } = await httpClient.post("/order", {
        imeis,
        notify,
        serviceId,
        batchName: batch,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      form.resetFields();
      batchSubject.next(null);
      setShow(false);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function importImeisFromFile(file: RcFile) {
    const content = await file.text();
    form.setFieldsValue({ imeis: content });
    return false;
  }

  function handleFormChange(cVal: OrderFormValues, aVal: OrderFormValues) {
    const { imeis } = aVal;
    const _imeis = imeis
      .split("\n")
      .map((imei) => imei.trim())
      .filter((imei) => !!imei);
    setTotal(_imeis.length);
  }

  return (
    <>
      <Form
        form={form}
        size="middle"
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
        initialValues={initVaules}
        onValuesChange={handleFormChange}
      >
        <Row gutter={[20, 10]}>
          <Col span={isMobile ? 18 : 22}>
            <Form.Item
              name="batch"
              label="Batch Name"
              rules={rules.batchName as any}
              tooltip="Batch name for order(s)"
            >
              <Input placeholder="My Batch 1" />
            </Form.Item>
          </Col>

          <Col span={isMobile ? 5 : 2}>
            <Form.Item
              name="notify"
              label="Notify"
              valuePropName="checked"
              rules={rules.notify as any}
              tooltip="Notify by email when batch ready"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name="service"
              label="Service"
              rules={rules.service as any}
              tooltip="Type to search service"
            >
              <SelService size="middle" setSelService={setServiceId} />
            </Form.Item>
          </Col>
        </Row>

        {serviceId && (
          <div
            style={{
              textAlign: "left",
              marginLeft: "160px",
              marginBottom: "-25px",
            }}
          >
            <Badge
              status={
                total > services.find((s: any) => s.id === serviceId).limit
                  ? "error"
                  : "warning"
              }
              text={
                total > services.find((s: any) => s.id === serviceId).limit
                  ? `Limit excedded Max: ${
                      services.find((s: any) => s.id === serviceId).limit
                    }`
                  : `Limit: ${total}/${
                      services.find((s: any) => s.id === serviceId).limit
                    }`
              }
            />
            &nbsp;
            <Progress
              steps={30}
              strokeColor="rgb(8, 170, 0)"
              trailColor="#00000050"
              percent={
                (total / services.find((s: any) => s.id === serviceId).limit) *
                100
              }
              showInfo={false}
              status={
                (total / services.find((s: any) => s.id === serviceId).limit) *
                  100 >
                100
                  ? "exception"
                  : undefined
              }
              size="small"
            />
          </div>
        )}

        <div style={{ textAlign: "right", marginBottom: "-20px" }}>
          <Space>
            <Button
              size="small"
              type="primary"
              disabled={!!!serviceId}
              style={{ zIndex: 100 }}
              onClick={() => {
                setSample(services.find((s: any) => s.id === serviceId));
                setShowSample(true);
              }}
            >
              Sample
            </Button>
            <Upload
              accept=".txt"
              showUploadList={false}
              beforeUpload={importImeisFromFile}
            >
              <Button type="primary" size="small" style={{ zIndex: 100 }}>
                Import File
              </Button>
            </Upload>
          </Space>
        </div>

        <Row>
          <Col span={24} style={{ zIndex: 0 }}>
            <Form.Item
              name="imeis"
              rules={rules.imeis as any}
              label="IMEI / Serial Number"
              tooltip="Each IMEI must start with new line."
            >
              <Input.TextArea allowClear className="input_imeis" />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Place Order
          </Button>
        </div>
      </Form>

      <Modal
        centered
        destroyOnClose
        footer={false}
        visible={showSample}
        onCancel={() => setShowSample(false)}
      >
        <Paragraph style={{ whiteSpace: "pre" }}>
          {!!sample ? sample!.description : ""}
        </Paragraph>
      </Modal>
    </>
  );
};

export default PlaceOrder;
