/* eslint-disable no-useless-concat */
import { reduce } from "lodash";
import { parse } from "json2csv";
import { saveAs } from "file-saver";

import jsonToTxt from "../utils/jsonToTxt";

export interface OrderTableData {
  key: any;
  id: number;
  batch: any;
  result: any;
  imei: string;
  status: string;
  updated: string;
  orderCost: number;
  orderType: string;
}

export interface ManualOrderExport {
  id: number;
  imei: string;
  result: number | null;
  state: "Processing" | "Failed";
}

export default (data: OrderTableData[], type: "TXT" | "CSV" = "TXT") => {
  switch (type) {
    case "TXT":
      txtExport(data);
      return;
    case "CSV":
      csvExport(data);
      return;
    default:
      txtExport(data);
      return;
  }
};

function txtExport(data: OrderTableData[]) {
  const pData = reduce<OrderTableData, string>(
    data,
    (pVal, cVal) => {
      let resultData = "";
      resultData += cVal.id + "\n";
      resultData += cVal.status + "\n";
      resultData += cVal.batch.service + "\n";
      resultData += cVal.imei + "\n";
      resultData += jsonToTxt(cVal.result ?? {}) + "\n";

      pVal += resultData + "\n\n";
      return pVal;
    },
    ""
  );

  const file = new File(
    [pData],
    `export-${new Date().toLocaleDateString()}.txt`,
    {
      type: "text/plain;charset=utf-8",
    }
  );
  saveAs(file);
}

function csvExport(data: OrderTableData[]) {
  const flatData = data.map((d) => {
    delete d.batch;
    const fData = {
      ...d,
      ...d.result,
    } as OrderTableData;
    delete fData.key;
    delete fData.result;

    return fData;
  });

  const headerKey = Object.keys(
    flatData.reduce((pVal, cVal) => ({ ...pVal, ...cVal }))
  );

  const csv = parse(flatData, { fields: headerKey });

  const file = new File(
    [csv],
    `export-${new Date().toLocaleDateString()}.csv`,
    {
      type: "text/csv;charset=utf-8",
    }
  );
  saveAs(file);
}

export function csvExportManulOrders(data: ManualOrderExport[]) {
  const headerKey = Object.keys(
    data.reduce((pVal, cVal) => ({ ...pVal, ...cVal }))
  );

  const csv = parse(data, { fields: headerKey });

  const file = new File(
    [csv],
    `export-${new Date().toLocaleDateString()}.csv`,
    {
      type: "text/csv;charset=utf-8",
    }
  );
  saveAs(file);
}
