import { ConfigAction, Configs, ConfigTypes } from "../actions/configs.action";

const initState: Configs = {
  theme: localStorage.getItem("theme") as any || "light",
  sessionToken: localStorage.getItem("session-key") as string,
};

export default (state: Configs = initState, action: ConfigAction) => {
  switch (action.type) {
    case ConfigTypes.SetConfig:
      const configs = action.payload;
      state = setConfigs(state, configs);
      return state;
    default:
      return state;
  }
};

function setConfigs(state: Configs, configs: any) {
  return { ...state, ...configs };
}
