import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import "jspdf/dist/polyfills.es.js";

import "./index.scss";
import App from "./App";
import store from "./state/store";
import reportWebVitals from "./reportWebVitals";

// moment.tz.setDefault("America/Virgin");

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
 * @description set global theme
 */
//  function setAppTheme(theme: string) {
//   const styleLink = `./assets/themes/theme.${theme}.css`;
//   const linkTag: LinkHTMLAttributes<any> = document.getElementById(
//     'theme-sheet'
//   ) as any;
//   linkTag.href = styleLink;
// }
