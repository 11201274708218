import { useSelector } from "react-redux";
import { Badge, message, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import httpClient from "../httpClient";
import { UserDetails } from "../../actions/userdetails.action";

export interface SelectServiceProps {
  manual?: boolean;
  size?: "small" | "middle" | "large";
  setSelService: (serviceId: number) => void;
}

const SelectService: React.FunctionComponent<SelectServiceProps> = ({
  size,
  manual,
  setSelService,
}) => {
  const userDetails: UserDetails = useSelector(
    (state: any) => state.userDetails
  );
  const [services, setService] = useState<any[]>([]);
  const [clearSearchInt, setClearSearchInt] = useState<NodeJS.Timeout>();

  useEffect(() => {
    fetchServices(" ", 100);
  }, []);

  async function fetchServices(search?: string, _size?: number) {
    try {
      const { data } = await httpClient.get("/service", {
        page: 0,
        size: _size || 20,
        filter: search,
        manual: manual ? true : undefined,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setService(data.services);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  function searchHandler(search: string) {
    if (clearSearchInt) clearTimeout(clearSearchInt);

    const timeOut = setTimeout(() => {
      fetchServices(search);
    }, 500);

    setClearSearchInt(timeOut);
  }

  return (
    <Select
      showSearch
      allowClear
      showArrow={false}
      filterOption={false}
      size={size ?? "small"}
      onSearch={searchHandler}
      style={{ width: "100%" }}
      suffixIcon={<SearchOutlined />}
      placeholder="Type to search"
      onChange={setSelService as any}
      notFoundContent="No Service Found."
    >
      {services.map((each: any) => (
        <Select.Option value={each.id}>
          {userDetails.role !== "Sub" ? `${each.price} - ` : ""}
          {each.title} &nbsp;
          {each.featured && (
            <Badge color="gold" text="Featured" status="processing" />
          )}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectService;
