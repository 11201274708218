import React, { useState } from "react";
import { Button, Col, Form, Input, InputNumber, message, Row } from "antd";

import httpClient from "../../../common/httpClient";

export interface CUPriceGroupProps {
  priceGroup: any;
  refresh: () => void;
  setShow: (show: boolean) => void;
}

const CUPriceGroup: React.FunctionComponent<CUPriceGroupProps> = ({
  setShow,
  refresh,
  priceGroup,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values: any) {
    setLoading(true);

    try {
      let res = null;
      if (priceGroup) {
        res = await httpClient.patch("/user/price-group", {
          ...values,
          id: priceGroup.id,
        });
      } else {
        res = await httpClient.post("/user/price-group", values);
      }

      const { data } = res;

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      if (priceGroup) {
        message.success("Price group updated.");
      } else {
        message.success("Price group created.");
      }
      refresh();
      setShow(false);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
        initialValues={priceGroup}
      >
        <Row gutter={[10, 10]} style={{ marginBottom: "-10px" }}>
          <Col span={18}>
            <Form.Item
              name="title"
              label="Title"
              tooltip="Name of price group."
              rules={[{ required: true, message: "Title is required." }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="discount"
              label="Discount"
              tooltip="Discount for this price group."
              rules={[{ required: true, message: "Title is required." }]}
            >
              <InputNumber style={{ width: "100%" }} min={0.01} max={99.99} />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button type="primary" loading={loading} htmlType="submit">
            {priceGroup ? "Update" : "Create"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CUPriceGroup;
