export interface LoginFormData {
  username: string;
  password: string;
  recaptcha: string;
}

export const initLoginValues: LoginFormData = {
  username: "",
  password: "",
  recaptcha: "",
};

export const rules = {
  username: [{ required: true, message: "Please input your Username!" }],
  password: [
    { required: true, message: "Please input your Password!" },
    { min: 5, max: 50, message: "Password must be 5-50 characters." },
  ],
};
