import {
  Table,
  Badge,
  Space,
  Input,
  Modal,
  Select,
  Button,
  Tooltip,
  message,
  DatePicker,
  Typography,
  Pagination,
  Popconfirm,
  InputNumber,
  Descriptions,
  notification,
} from "antd";
import moment from "moment";
import { truncate } from "lodash";
import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { SyncOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";

import OrderPreview from "./OrdersPreview";
import httpClient from "../../common/httpClient";
import exportOrders from "../../common/order.export";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title, Link } = Typography;

export interface BatchHistoryProps {
  preview?: any;
  admin?: boolean;
}

const BatchHistory: React.FunctionComponent<BatchHistoryProps> = ({
  admin,
  preview,
}) => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState("");
  const [view, setView] = useState(false);
  const [batches, setBatches] = useState();
  const [status, setBatchStatus] = useState();
  const [services, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState("");
  const [selRows, setSelRows] = useState<any[]>([]);
  const [batchPreview, setBatchPreview] = useState();
  const [selService, setSelService] = useState<any>();
  const [size, setSize] = useState(preview ? 50 : 50);
  const [onSubuserHover, setSubuserHover] = useState(false);
  const [search, setSearch] = useState<string | undefined>();
  const [batchId, setBatchId] = useState<number | undefined>();
  const [subusers, setSubsuers] = useState<string | undefined>();
  const [clearSearchInt, setClearSearchInt] = useState<NodeJS.Timeout>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const [toDate, setToDate] = useState<string | null>();
  const [fromDate, setFromDate] = useState<string | null>();
  const [toDateM, setToDateM] = useState<moment.Moment | null>();
  const [fromDateM, setFromDateM] = useState<moment.Moment | null>();

  const columns: ColumnsType = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sorter: {
        compare: (a: any, b: any, ord) => {
          switch (ord) {
            case "ascend":
              return a.id - b.id;
            case "descend":
              return b.id - a.id;
            default:
              return b.id - a.id;
          }
        },
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a: any, b: any, ord) => {
          switch (ord) {
            case "ascend":
              return a.status.length - b.status.length;
            case "descend":
              return b.status.length - a.status.length;
            default:
              return b.status.length - a.status.length;
          }
        },
      },
      render: (txt: string) => {
        switch (txt) {
          case "Pending":
            return <Badge status="processing" text="Pending" />;
          case "Completed":
            return <Badge status="success" text="Completed" />;
          default:
            return <Badge status="processing" text="Pending" />;
        }
      },
    },
    { title: "Title", dataIndex: "title", key: "title" },
    {
      key: "user",
      title: "User",
      dataIndex: "user",
      render: (txt: any, rec: any) => rec.user.username,
    },
    {
      key: "ip",
      title: "IP",
      dataIndex: "ip",
    },
    {
      key: "service",
      title: "Service",
      width: "200px",
      dataIndex: "service",
      render: (txt: string) =>
        txt.length > 28 ? (
          <Tooltip title={txt}>{truncate(txt, { length: 28 })}</Tooltip>
        ) : (
          txt
        ),
    },
    {
      key: "orders",
      width: "100px",
      dataIndex: "orders",
      title: "Orders",
    },
    admin
      ? {
          key: "tag",
          title: "Tag",
          // width: "150px",
          dataIndex: "apiTag",
        }
      : {},
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (txt: string) =>
        new Date(txt).toLocaleString("en-US", {
          timeZone: "America/Virgin",
        }),
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "",
      render: (_, record: any) => (
        <Space>
          {admin && (
            <Popconfirm
              okText="Reject"
              cancelText="Cancel"
              placement="topRight"
              onConfirm={() => rejectBatchs([record.id])}
              title={
                "Are you sure you want to reject batch ?\nThis process is irreversible."
              }
            >
              <Link>Reject</Link>
            </Popconfirm>
          )}
          <Link
            onClick={() => {
              setBatchPreview(record);
              setView(true);
            }}
          >
            View
          </Link>
          <Link
            onClick={async () => {
              await downloadBatch(record.id);
            }}
          >
            Download
          </Link>
        </Space>
      ),
    },
  ];

  /**
   * @description handle search effect
   */
  useEffect(() => {
    fetchBatches();
  }, [page, size]);

  async function rejectBatchs(ids: number[]) {
    try {
      const { data } = await httpClient.post("/batch/admin/reject-batch", {
        ids,
      });
      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      notification.success({ message: "Batch rejected." });
      fetchBatches();
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  async function fetchServices(search?: string) {
    try {
      const { data } = await httpClient.get("/service", {
        page: 0,
        size: 10,
        filter: search,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setService(data.services);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  function searchHandler(search: string) {
    if (clearSearchInt) clearTimeout(clearSearchInt);

    const timeOut = setTimeout(() => {
      fetchServices(search);
    }, 500);

    setClearSearchInt(timeOut);
  }

  async function downloadBatch(id: number) {
    try {
      const { data } = await httpClient.get("/order", {
        size,
        admin,
        all: true,
        batchId: id,
        page: page - 1,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      exportOrders(data.orders, "CSV");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
    }
  }

  /**
   * @description fetch batches from remote api
   */
  async function fetchBatches() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/batch", {
        admin,
        status,
        search,
        batchId,
        size: size,
        page: page - 1,
        serviceId: selService,
        to: toDateM ? new Date(toDate!).toISOString() : null,
        from: fromDateM ? new Date(fromDate!).toISOString() : null,
        subusers: subusers
          ?.split("\n")
          .map((s) => s.trim())
          .filter((s) => !!s),
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setTotal(data.total);
      setCompleted(data.completed);

      const _batches = data.batches.map((batch: any, index: number) => {
        batch["key"] = index;
        return batch;
      });

      setBatches(_batches);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  function handleDateChange(
    date: [moment.Moment, moment.Moment],
    dateString: [string, string]
  ) {
    if (date) {
      setToDate(dateString[1]);
      setFromDate(dateString[0]);
      setToDateM(date[1]);
      setFromDateM(date[0]);
    } else {
      setToDate(null);
      setFromDate(null);
      setToDateM(null);
      setFromDateM(null);
    }
  }

  async function handleRequeueBatch() {
    const ids = selRows.map((b) => b.id);
    try {
      const { data } = await httpClient.post("/queue/requeue", { ids });
      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      } else {
        message.success({ content: "Batchs requeued" });
      }
      fetchBatches();
    } catch (err) {}
  }

  return (
    <>
      <div className="bach_preview_head_title_wrapper">
        <div>
          <Title level={5}>Batch History</Title>
        </div>

        <div>
          <Tooltip title="Refresh">
            <Button
              type="link"
              loading={loading}
              disabled={!!!total}
              onClick={fetchBatches}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </div>
      </div>

      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        <Descriptions.Item
          label={<Badge text="Total" status="default" color="blue" />}
        >
          {total}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Badge text="Pending" status="processing" color="gold" />}
        >
          {parseInt(total) - parseInt(completed)}
        </Descriptions.Item>
        <Descriptions.Item label={<Badge text="Completed" status="success" />}>
          {completed}
        </Descriptions.Item>
      </Descriptions>

      <div className="batch_filter_wrapper">
        <div
          style={
            isMobile
              ? { width: "100%" }
              : { position: "absolute", zIndex: 100, width: "200px" }
          }
        >
          <Input.TextArea
            size="small"
            value={subusers}
            // disabled={!!!total}
            placeholder="Search Sub-Users"
            className="imei_search_text_area"
            onMouseOver={() => setSubuserHover(true)}
            onMouseLeave={() => setSubuserHover(false)}
            onChange={(e) => setSubsuers(e.target.value as any)}
            style={{
              minHeight: onSubuserHover ? "100px" : "25px",
              maxHeight: onSubuserHover ? "100px" : "25px",
            }}
          />
        </div>

        <Space
          style={
            !isMobile
              ? { marginLeft: "210px" }
              : { marginTop: "10px", width: "100%" }
          }
        >
          <div style={{ width: isMobile ? "100%" : undefined }}>
            <InputNumber
              size="small"
              value={batchId}
              placeholder="Search batch id"
              onChange={(e) => setBatchId(e)}
              style={{ width: isMobile ? "100%" : "150px" }}
            />
          </div>

          <div style={{ width: isMobile ? "100%" : undefined }}>
            <Input
              allowClear
              size="small"
              value={search}
              style={{ width: isMobile ? "100%" : "150px" }}
              placeholder="Search batch title"
              onChange={(e) => setSearch(e.target.value as any)}
            />
          </div>
        </Space>

        <div
          style={{
            width: isMobile ? "100%" : "300px",
            marginTop: isMobile ? "10px" : undefined,
          }}
        >
          <Select
            showSearch
            allowClear
            size="small"
            showArrow={false}
            value={selService}
            filterOption={false}
            onSearch={searchHandler}
            style={{ width: "100%" }}
            onChange={setSelService as any}
            notFoundContent="No Service Found."
            placeholder="Type to search service"
          >
            {services.map((each: any) => (
              <Select.Option value={each.id}>
                {each.title}{" "}
                {each.featured && (
                  <Badge color="gold" text="Featured" status="processing" />
                )}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div
          style={isMobile ? { width: "100%", marginTop: "10px" } : undefined}
        >
          <RangePicker
            allowClear
            size="small"
            style={{ width: "100%" }}
            allowEmpty={[true, true]}
            onChange={handleDateChange as any}
            value={[fromDateM ?? null, toDateM ?? null]}
          />
        </div>

        <div
          style={isMobile ? { width: "100%", marginTop: "10px" } : undefined}
        >
          <Select
            allowClear
            size="small"
            value={status}
            placeholder="Status"
            onChange={setBatchStatus as any}
            style={{ width: isMobile ? "100%" : 120 }}
          >
            <Option value="Pending">Pending</Option>
            <Option value="Completed">Completed</Option>
          </Select>
        </div>

        <div
          style={isMobile ? { width: "100%", marginTop: "10px" } : undefined}
        >
          {admin && (
            <Button
              danger
              size="small"
              type="primary"
              disabled={selRows.length <= 0 || loading}
              onClick={() => rejectBatchs(selRows.map((b) => b.id))}
              style={{
                width: isMobile ? "100%" : undefined,
                marginRight: "5px",
              }}
            >
              Reject
            </Button>
          )}
          {admin && (
            <Button
              size="small"
              type="primary"
              onClick={handleRequeueBatch}
              disabled={selRows.length <= 0 || loading}
              style={{
                marginRight: "5px",
                width: isMobile ? "100%" : undefined,
              }}
            >
              Requeue
            </Button>
          )}
          <Button
            size="small"
            type="primary"
            onClick={fetchBatches}
            style={{ width: isMobile ? "100%" : undefined }}
          >
            Filter
          </Button>
        </div>
      </div>

      <Table
        sticky
        size="small"
        loading={loading}
        pagination={false}
        dataSource={batches}
        scroll={{ x: true }}
        columns={columns as any}
        className={preview ? "order_table" : "order_table_history"}
        rowSelection={{
          selectedRowKeys: selRows.map((row) => row.key),
          onChange: (keys, selRowss) => setSelRows(selRowss),
        }}
      />

      <div className="pagination">
        <Pagination
          size="small"
          current={page}
          pageSize={size}
          showSizeChanger
          total={parseInt(total)}
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>

      <Modal
        centered
        visible={view}
        footer={false}
        title="View batch"
        onCancel={() => setView(false)}
        width={isMobile ? "100%" : "80%"}
      >
        <OrderPreview preview={batchPreview} admin={admin} />
      </Modal>
    </>
  );
};

export default BatchHistory;
