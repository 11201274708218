import {
  Menu,
  Table,
  Badge,
  Modal,
  Space,
  Input,
  Select,
  Button,
  Tooltip,
  message,
  Dropdown,
  DatePicker,
  Typography,
  Pagination,
  Descriptions,
} from "antd";
import {
  PlusOutlined,
  SyncOutlined,
  ExportOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import print from "print-js";
import { truncate } from "lodash";
import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";

import PlaceOrder from "./PlaceOrder";
import ExportDialog from "./ExportDialog";
import jsonToTxt from "../../utils/jsonToTxt";
import httpClient from "../../common/httpClient";
import genLabel from "../../common/generateLabel";
import PlacingLoader from "./OrdersPlacingLoader";
import orderExport from "../../common/order.export";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Paragraph, Title, Link } = Typography;

export interface OrdersPreviewProps {
  preview?: any;
  admin?: boolean;
  placeable?: boolean;
}

const OrdersPreview: React.FunctionComponent<OrdersPreviewProps> = ({
  admin,
  preview,
  placeable,
}) => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [orderStatus, setOrderStatus] = useState();
  const [selRows, setSelRows] = useState<any[]>([]);
  const [showExport, setShowExport] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [size, setSize] = useState(preview ? 50 : 50);
  const [onSearchHover, setSearchHover] = useState(false);
  const [onSubuserHover, setSubuserHover] = useState(false);
  const [search, setSearch] = useState<string | undefined>();
  const [subusers, setSubsuers] = useState<string | undefined>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const [toDate, setToDate] = useState<string | null>();
  const [fromDate, setFromDate] = useState<string | null>();
  const [toDateM, setToDateM] = useState<moment.Moment | null>();
  const [fromDateM, setFromDateM] = useState<moment.Moment | null>();

  const [meta, setMeta] = useState({
    id: 0,
    title: "",
    failed: "0",
    success: "0",
    waiting: "0",
    created: "",
    updated: "",
    service: "",
    processing: "0",
    completed: false,
  });

  const columns: ColumnsType<any> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sorter: {
        compare: (a: any, b: any, ord) => {
          switch (ord) {
            case "ascend":
              return a.id - b.id;
            case "descend":
              return b.id - a.id;
            default:
              return b.id - a.id;
          }
        },
      },
    },
    {
      key: "status",
      width: "100px",
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a: any, b: any, ord) => {
          switch (ord) {
            case "ascend":
              return a.status.length - b.status.length;
            case "descend":
              return b.status.length - a.status.length;
            default:
              return b.status.length - a.status.length;
          }
        },
      },
      render: (txt: string) => {
        switch (txt) {
          case "Processing":
            return <Badge status="processing" text="Processing" />;
          case "Success":
            return <Badge status="success" text="Success" />;
          case "Waiting":
            return <Badge status="warning" text="Waiting" />;
          case "Failed":
            return <Badge status="error" text="Failed" />;
          default:
            return <Badge status="processing" text="Processing" />;
        }
      },
    },
    {
      key: "imei",
      title: "IMEI/SN",
      dataIndex: "imei",
      sorter: {
        compare: (a: any, b: any, ord) => {
          switch (ord) {
            case "ascend":
              return a.imei - b.imei;
            case "descend":
              return b.imei - a.imei;
            default:
              return b.imei - a.imei;
          }
        },
      },
    },
    {
      key: "role",
      width: "70px",
      title: "Role",
      dataIndex: "batch",
      render: (txt: any) => txt.user.role,
    },
    {
      key: "service",
      title: "Service",
      dataIndex: "batch",
      render: (txt: any) => txt.service,
    },
    {
      key: "user",
      title: "User",
      dataIndex: "batch",
      render: (txt: any) => txt.user.username,
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (txt: string) =>
        new Date(txt).toLocaleString("en-US", {
          timeZone: "America/Virgin",
        }),
    },
    {
      key: "result",
      width: "250px",
      title: "Result",
      dataIndex: "result",
      render: (txt: any) => {
        const res = jsonToTxt(txt ?? {});
        return !!txt ? <Paragraph copyable>{res}</Paragraph> : "No Result";
      },
    },
    {
      key: "x",
      width: "80px",
      dataIndex: "x",
      title: "Action",
      render: (txt: string, record: any, index: number) => (
        <Link
          disabled={!!!record.result}
          onClick={() => handlePrintLabel([record])}
        >
          Print
        </Link>
      ),
    },
  ];

  /**
   * @description handle search effect
   */
  useEffect(() => {
    setLoading(true);
    fetchOrders().finally(() => {
      setLoading(false);
    });
  }, [page, size, preview]);

  /**
   * @description print multiple records
   * @param records records array
   */
  async function handlePrintLabel(records: any[]) {
    const flatData = records
      .filter((d) => !!d.result)
      .map((d: any) => {
        const fData = { result: d.result, serial: d.imei };

        return fData;
      });

    if (!flatData) {
      message.warning("No printable orders selected.");
      return;
    }
    const pdfs = await genLabel(flatData);

    print({
      printable: pdfs,
    });
  }

  /**
   * @description fetch orders from remote api
   */
  async function fetchOrders() {
    if (!!!store.getState().configs.sessionToken) return;
    if (preview && preview < 0) return;

    setSelRows([]);

    try {
      const { data } = await httpClient.get("/order", {
        size,
        admin,
        orderStatus,
        page: page - 1,
        search: search
          ?.split("\n")
          .map((s) => s.trim())
          .filter((s) => !!s),
        subusers: subusers
          ?.split("\n")
          .map((s) => s.trim())
          .filter((s) => !!s),
        to: toDateM ? new Date(toDate!).toISOString() : null,
        from: fromDateM ? new Date(fromDate!)?.toISOString() : null,
        batchId: preview ? preview.id : undefined,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      const _orders = data.orders.map((_order: any, index: number) => {
        _order["key"] = index;
        return _order;
      });

      setOrders(_orders);

      setMeta({
        ...meta,
        failed: data.failed,
        success: data.success,
        waiting: data.waiting,
        completed: data.completed,
        processing: data.processing,
      });

      if (preview) {
        setMeta({
          success: data.success,
          waiting: data.waiting,
          completed: data.completed,
          processing: data.processing,
          id: preview.id,
          title: preview.title,
          failed: data.failed,
          created: preview.created,
          updated: preview.updated,
          service: preview.service,
        });
      }

      const totalOrders =
        parseInt(data.waiting) +
        parseInt(data.success) +
        parseInt(data.failed) +
        parseInt(data.processing);

      setTotal(totalOrders);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  /**
   * @description handle export selected fields
   * @param param0
   */
  function handleExportSelected({ key }: any) {
    const _selected = selRows.map((row) => row.id);
    const _orders = orders.filter((order) => _selected.includes(order.id));
    // console.log(_orders);
    orderExport(_orders, key);
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  function handleDateChange(
    date: [moment.Moment, moment.Moment],
    dateString: [string, string]
  ) {
    if (date) {
      setToDate(dateString[1]);
      setFromDate(dateString[0]);
      setToDateM(date[1]);
      setFromDateM(date[0]);
    } else {
      setToDate(null);
      setFromDate(null);
      setToDateM(null);
      setFromDateM(null);
    }
  }

  return (
    <>
      {placeable ? (
        <div style={{ marginBottom: "5px" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: "100%" }}
            onClick={() => setShowDialog(true)}
          >
            Place Order
          </Button>
        </div>
      ) : null}
      <div className="bach_preview_head_title_wrapper">
        <div>
          <Title level={5}>
            {preview ? "Batch Details:" : "Orders History"}
          </Title>
        </div>
        <div>
          <Tooltip title="Refresh">
            <Button
              type="link"
              disabled={!!!total}
              onClick={async () => {
                setLoading(true);
                fetchOrders().finally(() => setLoading(false));
              }}
              icon={<SyncOutlined spin={loading} />}
            />
          </Tooltip>
        </div>
      </div>

      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        {preview && (
          <>
            <Descriptions.Item label="Batch ID">{meta.id}</Descriptions.Item>
            <Descriptions.Item label="Batch Name">
              <Tooltip title={meta.title}>
                {truncate(meta.title, { length: 30 })}
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="Order Count">{total}</Descriptions.Item>
            <Descriptions.Item label="Service">
              <Tooltip title={meta.service}>
                {truncate(meta.service, { length: 30 })}
              </Tooltip>
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item
          label={<Badge text="Processing" status="processing" />}
        >
          {meta.processing}
        </Descriptions.Item>
        <Descriptions.Item label={<Badge text="Waiting" status="warning" />}>
          {meta.waiting}
        </Descriptions.Item>
        <Descriptions.Item label={<Badge text="Success" status="success" />}>
          {meta.success}
        </Descriptions.Item>
        <Descriptions.Item label={<Badge text="Failed" status="error" />}>
          {meta.failed}
        </Descriptions.Item>
      </Descriptions>

      {!preview || total > 0 ? (
        <div
          style={{ marginTop: "10px", width: isMobile ? "100%" : undefined }}
        >
          <div
            style={
              !isMobile ? { position: "absolute", zIndex: 100 } : undefined
            }
          >
            <Input.TextArea
              size="small"
              value={search}
              placeholder="Search IMEI(s)"
              className="imei_search_text_area"
              onMouseOver={() => setSearchHover(true)}
              onMouseLeave={() => setSearchHover(false)}
              onChange={(e) => setSearch(e.target.value as any)}
              style={{
                width: isMobile ? "100%" : "180px",
                minHeight: onSearchHover ? "100px" : "25px",
                maxHeight: onSearchHover ? "100px" : "25px",
              }}
            />
          </div>
          <div
            style={
              !isMobile
                ? {
                    zIndex: 100,
                    marginLeft: "190px",
                    position: "absolute",
                  }
                : { marginTop: "10px" }
            }
          >
            <Input.TextArea
              size="small"
              value={subusers}
              placeholder="Search Sub-Users"
              className="imei_search_text_area"
              onMouseOver={() => setSubuserHover(true)}
              onMouseLeave={() => setSubuserHover(false)}
              onChange={(e) => setSubsuers(e.target.value as any)}
              style={{
                width: isMobile ? "100%" : "180px",
                minHeight: onSubuserHover ? "100px" : "25px",
                maxHeight: onSubuserHover ? "100px" : "25px",
              }}
            />
          </div>
        </div>
      ) : null}

      {!preview || total > 0 ? (
        <>
          <div className="order_filter_wrapper">
            <div style={{ width: "100%" }}>
              <Space
                style={{
                  width: "100%",
                  marginLeft: !isMobile ? "375px" : "0",
                }}
              >
                <Select
                  allowClear
                  size="small"
                  value={orderStatus}
                  placeholder="Status"
                  onChange={setOrderStatus as any}
                  style={{ width: isMobile ? 70 : 110 }}
                >
                  <Option value="Failed">Failed</Option>
                  <Option value="Waiting">Waiting</Option>
                  <Option value="Success">Success</Option>
                  <Option value="Processing">Processing</Option>
                </Select>

                <RangePicker
                  allowClear
                  size="small"
                  allowEmpty={[true, true]}
                  style={{ width: isMobile ? undefined : 210 }}
                  onChange={handleDateChange as any}
                  value={[fromDateM ?? null, toDateM ?? null]}
                />

                <Button size="small" type="primary" onClick={fetchOrders}>
                  Filter
                </Button>

                <Button
                  size="small"
                  type="primary"
                  onClick={() => setShowExport(true)}
                >
                  Export
                </Button>
              </Space>
            </div>

            <Space
              direction={isMobile ? "vertical" : "horizontal"}
              style={
                isMobile ? { width: "100%", marginTop: "10px" } : undefined
              }
            >
              <Dropdown
                disabled={!selRows.length}
                overlay={
                  <Menu onClick={handleExportSelected}>
                    <Menu.Item key="CSV">CSV</Menu.Item>
                    <Menu.Item key="TXT">Plane Text</Menu.Item>
                  </Menu>
                }
              >
                <Button
                  size="small"
                  type="primary"
                  icon={<ExportOutlined />}
                  style={{ width: "100%" }}
                >
                  Export Selected
                </Button>
              </Dropdown>

              <Button
                size="small"
                type="primary"
                style={{ width: "100%" }}
                icon={<PrinterOutlined />}
                disabled={!!!total || !selRows.length}
                onClick={() => handlePrintLabel(selRows)}
              >
                Print Selected
              </Button>
            </Space>
          </div>

          <Table
            sticky
            size="small"
            loading={loading}
            tableLayout="auto"
            pagination={false}
            dataSource={orders}
            scroll={{ x: true }}
            columns={
              preview
                ? columns.filter((c) => {
                    return !["role", "cost", "service", "user"].includes(
                      c.key as any
                    );
                  })
                : columns
            }
            className={preview ? "order_table" : "order_table_history"}
            rowSelection={{
              selectedRowKeys: selRows.map((row) => row.key),
              onChange: (keys, selRowss) => setSelRows(selRowss),
            }}
          />
          <div className="pagination">
            <Pagination
              size="small"
              total={total}
              current={page}
              pageSize={size}
              showSizeChanger
              onChange={handlePaginationChange}
              pageSizeOptions={["50", "100", "200", "500"]}
            />
          </div>
        </>
      ) : (
        !!preview.id && <PlacingLoader />
      )}

      <Modal
        centered
        destroyOnClose
        footer={false}
        title="Place Order"
        visible={showDialog}
        okText="Place order"
        maskClosable={false}
        width={isMobile ? "100%" : "60%"}
        onCancel={() => {
          setShowDialog(false);
        }}
      >
        <PlaceOrder show={showDialog} setShow={setShowDialog as any} />
      </Modal>

      <Modal
        centered
        footer={false}
        destroyOnClose
        title="Export"
        maskClosable={false}
        visible={showExport}
        width={isMobile ? "100%" : "40%"}
        onCancel={() => setShowExport(false)}
      >
        <ExportDialog close={() => setShowExport(false)} />
      </Modal>
    </>
  );
};

export default OrdersPreview;
