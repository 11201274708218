import { ColumnsType } from "antd/lib/table";
import { FunctionComponent, useState } from "react";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, InputNumber, message, Row, Table } from "antd";

import httpClient from "../../../common/httpClient";
import { useListManagedServices } from "../../../hooks/managed-services";

interface ManagedServicesProps {
  user: any;
}

const ManagedServices: FunctionComponent<ManagedServicesProps> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [discountedPrices, setDiscountedPrices] = useState<any>({});
  const { data, isLoading, isSuccess, refetch } = useListManagedServices({
    username: user.username,
  });

  async function addDisableService(dto: { id: number; username: string }) {
    const { id, username } = dto;
    setLoading(true);
    try {
      const { data } = await httpClient.post("/user/admin/disabled-service", {
        username,
        service: id,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await refetch();
      message.success("Service disabled");
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  async function clearDisabledService(dto: { id: number; username: string }) {
    const { id, username } = dto;
    setLoading(true);
    try {
      const { data } = await httpClient.post(
        "/user/admin/clear-disabled-service",
        { id, username }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await refetch();
      message.success("Service enabled");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function clearDiscount(args: { id: number; username: string }) {
    const { id, username } = args;
    setLoading(true);
    try {
      const { data } = await httpClient.delete("/service/discount-service", {
        username,
        serviceId: id,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Discount cleard.");
      await refetch();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function clearAllDiscountServices(args: { username: string }) {
    const { username } = args;
    setLoading(true);
    try {
      const { data } = await httpClient.delete(
        "/service/management/global/clear-user-discount",
        {
          username,
        }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Reset prices for all services");
      setDiscountedPrices({});
      await refetch();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function updateDiscountedPrice(args: {
    id: number;
    price: number;
    username: string;
  }) {
    const { id, price, username } = args;
    setLoading(true);
    try {
      const { data } = await httpClient.post("/service/discount-service", {
        username,
        serviceId: id,
        newPrice: price,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Discount added");
      await refetch();
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  const columns: ColumnsType = [
    {
      key: "disabled",
      width: "200px",
      dataIndex: "disabled",
      title: "Enable / Disabled",
      render: (v: boolean, r: any) => (
        <Checkbox
          checked={!v}
          onChange={(e) => {
            if (e.target.checked) {
              clearDisabledService({ id: r.id, username: user.username });
            } else {
              addDisableService({ id: r.id, username: user.username });
            }
          }}
        />
      ),
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
    },
    {
      key: "actualPrice",
      dataIndex: "actual_price",
      title: "Actual Price",
    },
    {
      key: "discounted_price",
      title: "Discounted Price",
      dataIndex: "discounted_price",
      render: (dp: number, record: any) => (
        <Row gutter={4}>
          <Col>
            <InputNumber
              min={0.0001}
              value={discountedPrices[record.id] || dp}
              onChange={(v) => {
                const newDiscountedPrices = { ...discountedPrices };
                newDiscountedPrices[record.id] = v;
                setDiscountedPrices(newDiscountedPrices);
              }}
            />
          </Col>
          <Col>
            <Button
              type="link"
              onClick={() =>
                updateDiscountedPrice({
                  id: record.id,
                  username: user.username,
                  price: discountedPrices[record.id],
                })
              }
            >
              <SaveOutlined />
            </Button>
          </Col>
          <Col>
            <Button
              danger
              type="link"
              onClick={() => {
                delete discountedPrices[record.id];
                setDiscountedPrices({ ...discountedPrices });
                clearDiscount({
                  id: record.id,
                  username: user.username,
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div />
        <div>
          <Button
            danger
            size="middle"
            type="primary"
            loading={loading}
            onClick={() =>
              clearAllDiscountServices({ username: user.username })
            }
          >
            Reset All Prices
          </Button>
        </div>
      </div>
      <Table
        sticky
        size="small"
        pagination={false}
        scroll={{ x: true }}
        columns={columns as any}
        loading={isLoading || loading}
        className="managed_service_table"
        dataSource={isSuccess ? data!.services : undefined}
      />
    </>
  );
};

export default ManagedServices;
