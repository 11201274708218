import React, { useEffect, useState } from "react";
import { Col, message, Row, Select, Form, InputNumber } from "antd";

import httpClient from "../../../common/httpClient";

export type SelectProviderServiceProps = any;

const SelectProviderService: React.FunctionComponent<
  SelectProviderServiceProps
> = (props) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [notListables, setNotListables] = useState<number[]>([]);
  const [selProvider, setSelProvider] = useState(
    props.selProvider ? props.selProvider.apiProvider.id : undefined
  );

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    if (notListables.includes(selProvider)) return;

    if (selProvider) fetchProviderServices();
  }, [selProvider]);

  async function fetchProviders() {
    try {
      const { data } = await httpClient.get("/provider");

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setProviders(data);

      const _notListables = data
        .filter((p: any) => {
          return !p.listable;
        })
        .map((p: any) => p.id);

      setNotListables(_notListables);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  /**
   * @description fetch groups
   * @param search search string
   */
  async function fetchProviderServices() {
    setLoading(true);
    try {
      const { data } = await httpClient.get("/provider/services", {
        providerId: selProvider,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setServices(data);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Row gutter={[10, 10]} style={{ overflow: "hidden" }}>
        <Col span={6}>
          <Form.Item
            noStyle
            {...props.field}
            name={[props.field.name, "providerId"]}
            fieldKey={[props.field.fieldKey, "providerId"]}
            rules={[{ required: true, message: "Provider is required." }]}
          >
            <Select
              {...props}
              allowClear
              showArrow={false}
              value={selProvider}
              placeholder="Provider"
              onChange={(val) => {
                setServices([]);
                setSelProvider(val as any);
              }}
              notFoundContent="No Groups Found."
            >
              {providers.map((each: any) => (
                <Select.Option key={each.id} value={each.id}>
                  {each.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item
            noStyle
            {...props.field}
            name={[props.field.name, "serviceId"]}
            fieldKey={[props.field.fieldKey, "serviceId"]}
            rules={[
              { required: true, message: "Provider service is required." },
            ]}
          >
            {notListables.includes(selProvider) ? (
              <InputNumber {...props} style={{ width: "100%" }} />
            ) : (
              <Select
                allowClear
                loading={loading}
                placeholder="Provider Service"
                notFoundContent="No Provider Selected."
              >
                {services.map((each: any) => (
                  <Select.Option key={each.id} value={each.id}>
                    {each.title}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item
            noStyle
            {...props.field}
            name={[props.field.name, "distributionRatio"]}
            fieldKey={[props.field.fieldKey, "distributionRatio"]}
            rules={[
              { required: true, message: "Distribution ratio is required." },
            ]}
          >
            <InputNumber {...props} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default SelectProviderService;
