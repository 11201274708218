import {
  UserDetails,
  UserDetailsTypes,
  UserDetailsAction,
} from "../actions/userdetails.action";
import { clone } from "lodash";

const persistUserData = localStorage.getItem("userDetails");
const initState: UserDetails = persistUserData
  ? JSON.parse(persistUserData as any)
  : {
      id: "",
      ips: [],
      email: "",
      credit: 0,
      rights: {},
      role: "Sub",
      username: "",
      promoCode: "",
      blocked: false,
      allowedPaymentMethods: ["Coinbase"],
    };

export default (state: UserDetails = initState, action: UserDetailsAction) => {
  switch (action.type) {
    case UserDetailsTypes.SetUserDetails:
      const configs = action.payload;
      state = setUserDetails(state, configs);
      return state;
    default:
      return state;
  }
};

function setUserDetails(state: UserDetails, userDetails: UserDetails) {
  const updatedUserData = clone({ ...state, ...userDetails });
  updatedUserData.allowedPaymentMethods = [...userDetails.allowedPaymentMethods]
  localStorage.setItem("userDetails", JSON.stringify(updatedUserData));
  return updatedUserData;
}
