import { Typography } from "antd";
import { useStore } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import httpClient from "../common/httpClient";

export interface InitProps {}

const { Text } = Typography;

const Init: React.FunctionComponent<InitProps> = () => {
  const store = useStore();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    checkSession();
    // console.log("HISTORY: ", history);
    // console.log("LOCATION: ", location);
  }, []);

  async function checkSession() {
    const sessionToken = store.getState().configs.sessionToken;
    const navigate = new URLSearchParams(location.search).get("navigate");

    if (!sessionToken) {
      history.replace("/login");
      return;
    }

    try {
      const { data } = await httpClient.get("/health");
      if (data.error) {
        history.replace("/login");
        return;
      }

      history.replace(navigate ?? "/");
    } catch (err) {
      history.replace("/login");
    }
  }

  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined />
          </div>
          <div style={{ textAlign: "center" }}>
            <Text>loading...</Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default Init;
