import { message } from "antd";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import httpClient from "../common/httpClient";

export type ListManagedUserServices = {
  username: string;
};

export type ManagedUserServices = {
  id: number;
  title: string;
  disabled: boolean;
  actual_price: number;
  discounted_price?: number;
};

export type ListManagedUserServicesResponse = {
  services: ManagedUserServices[];
};

export function useListManagedServices(query: ListManagedUserServices) {
  const history = useHistory();
  const { data, refetch, isFetching, error, isError, isSuccess, isLoading } =
    useQuery<ListManagedUserServicesResponse, any>(
      ["list-managed-services", query],
      async () => {
        const { data } = await httpClient.get("/service/management", {
          username: query.username,
        });

        return data;
      },
      {
        refetchOnWindowFocus: false,
      }
    );

  if (isError) {
    // const errorTitle = error.response
    //   ? error.response.data.error
    //   : "Something went wrong";
    const errorMsg = error.response
      ? error.response.data.message
      : "Failed to fetch ";

    if (errorMsg === "Invalid session key.") history.replace("/login");
    message.error({ content: errorMsg });
  }

  return { data, refetch, isFetching, error, isSuccess, isLoading };
}

export type ManagedGlobalServices = {
  id: number;
  title: string;
};

export type ListManagedGlobalServicesResponse = {
  services: ManagedGlobalServices[];
};

export function useListManagedGlobalServices() {
  const history = useHistory();
  const { data, refetch, isFetching, error, isError, isSuccess, isLoading } =
    useQuery<ListManagedUserServicesResponse, any>(
      "list-managed-global-services",
      async () => {
        const { data } = await httpClient.get("/service/management/global");

        return data;
      },
      {
        refetchOnWindowFocus: false,
      }
    );

  if (isError) {
    // const errorTitle = error.response
    //   ? error.response.data.error
    //   : "Something went wrong";
    const errorMsg = error.response
      ? error.response.data.message
      : "Failed to fetch ";

    if (errorMsg === "Invalid session key.") history.replace("/login");
    message.error({ content: errorMsg });
  }

  return { data, refetch, isFetching, error, isSuccess, isLoading };
}
