/* eslint-disable no-dupe-keys */
import {
  Space,
  Table,
  Input,
  Modal,
  Badge,
  Select,
  Button,
  Tooltip,
  message,
  Typography,
  Pagination,
  Descriptions,
} from "antd";
import { parse } from "json2csv";
import { truncate } from "lodash";
import { saveAs } from "file-saver";
import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { SyncOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";

import UsersAction from "./UsersAction";
import { usersListSubject } from "./user.utils";
import httpClient from "../../../common/httpClient";

const { Title, Link } = Typography;

export interface UsersListProps {}

const UsersList: React.FunctionComponent<UsersListProps> = () => {
  const store = useStore();
  const [role, setRole] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(100);
  const [actuser, setActuser] = useState<any>();
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // const [selRows, setSelRows] = useState<any[]>([]);
  const [showAction, setShowAction] = useState(false);
  const [searchHover, setSearchHover] = useState(false);
  const [search, setSearch] = useState<string | undefined>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    // Refresh users list on order placed
    const unsub = usersListSubject.subscribe(async () => {
      await fetchUsers();
    });

    return () => {
      unsub.unsubscribe();
    };
  }, [size, page]);

  const columns: ColumnsType = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      render: (txt: string) => truncate(txt, { length: 10 }),
    },
    {
      key: "username",
      title: "Username",
      dataIndex: "username",
    },
    {
      key: "firstName",
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      key: "lastName",
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      key: "phone",
      title: "Phone",
      dataIndex: "phone",
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
    },
    {
      key: "company",
      title: "Company",
      dataIndex: "company",
    },
    {
      key: "role",
      title: "Role",
      width: "80px",
      dataIndex: "role",
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      key: "email",
      width: "100px",
      title: "Approved",
      dataIndex: "approved",
      render: (txt: boolean) => (txt ? "Yes" : "No"),
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (txt: any) =>
        new Date(txt).toLocaleString("en-US", { timeZone: "America/Virgin" }),
    },
    {
      width: "100px",
      key: "blocked",
      title: "Blocked",
      dataIndex: "blocked",
      render: (txt: boolean) => (txt ? "Yes" : "No"),
    },
    {
      key: "credit",
      title: "Credit",
      dataIndex: "credit",
    },
    {
      width: "100px",
      key: "priceGroup",
      title: "Price Group",
      dataIndex: "priceGroup",
      render: (txt: any) => (txt ? txt.title : "None"),
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "",
      render: (_, record: any) => (
        <Link
          onClick={() => {
            handleOpenAction(record);
          }}
        >
          Action
        </Link>
      ),
    },
  ];

  /**
   * @description fetch batches from remote api
   */
  async function fetchUsers() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/user/admin", {
        role,
        size: size,
        page: page - 1,
        search: search
          ?.split("\n")
          .map((s) => s.trim())
          .filter((s) => !!s),
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setTotal(data.total);

      const _users = data.users.map((batch: any, index: number) => {
        batch["key"] = index;
        return batch;
      });

      setUsers(_users);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description Open user action dialog
   * @param record user info
   */
  function handleOpenAction(record: any) {
    setActuser(record);
    setShowAction(true);
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  async function exportAllUsers() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/user/admin", {
        all: true,
        size: size,
        page: page - 1,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      handleExportUsers(data.users);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description export users
   * @param users users list
   */
  function handleExportUsers(users: any) {
    const flatData = users.map((d: any) => {
      const Role = d.role;
      const Email = d.email;
      const Credit = d.credit;
      const Username = d.username;
      const PriceGroup = d.priceGroup ? d.priceGroup.title : "-";

      return { Email, Username, Role, Credit, PriceGroup };
    });

    const headerKey = Object.keys(
      flatData.reduce((pVal: any, cVal: any) => ({ ...pVal, ...cVal }))
    );

    const csv = parse(flatData, { fields: headerKey });

    const file = new File(
      [csv],
      `export-users-${new Date().toLocaleDateString()}.csv`,
      {
        type: "text/csv;charset=utf-8",
      }
    );
    saveAs(file);
  }

  return (
    <>
      <div className="bach_preview_head_title_wrapper">
        <div>
          <Title level={5}>Users</Title>
        </div>
        <div>
          <Tooltip title="Refresh">
            <Button
              type="link"
              loading={loading}
              disabled={!!!total}
              onClick={fetchUsers}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </div>
      </div>

      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        <Descriptions.Item
          label={<Badge text="Total" status="default" color="blue" />}
        >
          {total}
        </Descriptions.Item>
      </Descriptions>

      <div className="admin_user_filter_wrapper">
        <div>
          <div
            style={
              !isMobile
                ? { position: "absolute", zIndex: 100 }
                : { width: "100%" }
            }
          >
            <Input.TextArea
              size="small"
              value={search}
              disabled={!!!total}
              placeholder="Search users"
              className="imei_search_text_area"
              onMouseOver={() => setSearchHover(true)}
              onMouseLeave={() => setSearchHover(false)}
              onChange={(e) => setSearch(e.target.value as any)}
              style={{
                width: isMobile ? "100%" : "200px",
                minHeight: searchHover ? "100px" : "25px",
                maxHeight: searchHover ? "100px" : "25px",
              }}
            />
          </div>
          <div
            style={
              !isMobile
                ? { marginLeft: "210px" }
                : { marginTop: "10px", width: "100%" }
            }
          >
            <Space style={{ width: "100%" }}>
              <Select
                allowClear
                size="small"
                value={role}
                placeholder="Role"
                filterOption={false}
                onChange={setRole as any}
                style={{ width: isMobile ? "240px" : "100px" }}
              >
                <Select.Option value="Sub">Sub</Select.Option>
                <Select.Option value="Main">Main</Select.Option>
                <Select.Option value="Admin">Admin</Select.Option>
              </Select>

              <Button size="small" type="primary" onClick={fetchUsers}>
                Filter
              </Button>

              {isMobile && (
                <Button
                  block
                  size="small"
                  type="primary"
                  onClick={exportAllUsers}
                >
                  Export all
                </Button>
              )}
            </Space>
          </div>
        </div>
        {!isMobile && (
          <div>
            <Button
              size="small"
              type="primary"
              onClick={exportAllUsers}
              style={
                isMobile ? { width: "100%", marginTop: "10px" } : undefined
              }
            >
              Export all
            </Button>
          </div>
        )}
      </div>

      <Table
        sticky
        size="small"
        loading={loading}
        pagination={false}
        dataSource={users}
        scroll={{ x: true }}
        columns={columns as any}
        className="admin_users_table"
        // rowSelection={{
        //   selectedRowKeys: selRows.map((row) => row.key),
        //   onChange: (keys, selRowss) => setSelRows(selRowss),
        // }}
      />
      <div className="pagination">
        <Pagination
          size="small"
          total={total}
          current={page}
          pageSize={size}
          showSizeChanger
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>
      <Modal
        centered
        width={isMobile ? "100%" : "60%"}
        footer={null}
        destroyOnClose
        title={`User ${actuser?.username}`}
        visible={showAction}
        onCancel={() => {
          setActuser(undefined);
          setShowAction(false);
        }}
      >
        <UsersAction user={actuser} />
      </Modal>
    </>
  );
};

export default UsersList;
