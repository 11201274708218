import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { Button, DatePicker, message, Table, Space } from "antd";

import httpClient from "../../common/httpClient";

export interface AnalyticsProps {
  username?: string;
}

const { RangePicker } = DatePicker;

const Analytics: React.FunctionComponent<AnalyticsProps> = ({ username }) => {
  const store = useStore();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState<string | null>();
  const [from, setFrom] = useState<string | null>();
  const [toM, setToM] = useState<moment.Moment | null>();
  const [fromM, setFromM] = useState<moment.Moment | null>();

  const columns: ColumnsType = [
    {
      key: "service",
      title: "Service",
      dataIndex: "service",
    },
    {
      key: "cost",
      dataIndex: "cost",
      title: "Credit Spend",
    },
    {
      key: "success",
      title: "Success",
      dataIndex: "success",
    },
    {
      key: "waiting",
      title: "Waiting",
      dataIndex: "waiting",
    },
    {
      key: "processing",
      title: "Processing",
      dataIndex: "processing",
    },
    {
      key: "failed",
      title: "Failed",
      dataIndex: "failed",
    },
  ];

  useEffect(() => {
    fetchAnalytics();
  }, []);

  async function fetchAnalytics() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);

    try {
      const { data } = await httpClient.get("/analytics", {
        username,
        to: toM ? new Date(to!).toISOString() : null,
        from: fromM ? new Date(from!).toISOString() : null,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      const keyedData = (data as any[]).map((d, index) => ({
        ...d,
        key: index,
      }));

      setData(keyedData);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  function handleDateChange(
    date: [moment.Moment, moment.Moment],
    dateString: [string, string]
  ) {
    if (date) {
      setTo(dateString[1]);
      setFrom(dateString[0]);
      setToM(date[1]);
      setFromM(date[0]);
    } else {
      setTo(null);
      setFrom(null);
      setToM(null);
      setFromM(null);
    }
  }

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div style={{ width: "100%", marginBottom: "10px" }}>
          <Space>
            <RangePicker
              allowClear
              size="small"
              allowEmpty={[true, true]}
              style={{ width: "200px" }}
              onChange={handleDateChange as any}
              value={[fromM ?? null, toM ?? null]}
            />

            <Button size="small" type="primary" onClick={fetchAnalytics}>
              Submit
            </Button>
          </Space>
        </div>

        <Table
          sticky
          size="small"
          loading={loading}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          className="order_table"
          columns={columns as any}
        />
      </Space>
    </>
  );
};

export default Analytics;
