import {
  Space,
  Switch,
  Avatar,
  Button,
  Divider,
  message,
  Tooltip,
  Typography,
} from "antd";
import {
  UserOutlined,
  SyncOutlined,
  DollarOutlined,
  LogoutOutlined,
  ControlOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import httpClient from "../../common/httpClient";
import { setConfigs } from "../../actions/configs.action";
import { batchSubject } from "../../Components/Batch/batch.utils";
import PaymentDailog from "../../Components/Payment/PaymentDialog";
import { UserDetails, setUserDetails } from "../../actions/userdetails.action";

export interface HeaderPopProps {
  theme: "light" | "dark";
  userDetails: UserDetails;
  setTheme: (theme: string) => void;
  refreshDetails: (details: UserDetails) => void;
}

const { Title, Link } = Typography;

const HeaderPop: React.FunctionComponent<HeaderPopProps> = ({
  theme,
  setTheme,
  userDetails,
  refreshDetails,
}) => {
  const history = useHistory();
  const [refreshing, setRefreshing] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const viewCredit =
    userDetails.role === "Sub" ? userDetails.rights.viewBalance : true;
  // const allowedCredit =
  //   userDetails.role === "Sub" ? userDetails.rights.addFunds : true;

  useEffect(() => {
    const sub = batchSubject.subscribe(async () => {
      await refreshUserDetails();
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  /**
   * @description change theme
   */
  function handleSetTheme(checked: boolean) {
    const themeType = checked ? "dark" : "light";
    setTheme!(themeType);
    document
      .getElementById("theme-sheet")!
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/styles/theme.${themeType}.css`
      );
  }

  /**
   * @description clear session token and navigate login screen
   */
  async function logout() {
    await httpClient.post("/logout");
    history.replace("/login");
  }

  /**
   * @description refresh user details
   */
  async function refreshUserDetails() {
    setRefreshing(true);
    try {
      const { data } = await httpClient.get("/user/info");
      if (!data.error) {
        refreshDetails(data);
        return;
      }

      message.error(data.message);
    } catch (err: any) {
      message.error(err);
    } finally {
      setRefreshing(false);
    }
  }

  return (
    <>
      <Space direction="vertical">
        <div className="user_details_pop_wrapper">
          <div className="username_avatar_wrapper">
            <div>
              <Avatar className="header_avatar" icon={<UserOutlined />} />
            </div>
            <div>
              <Title level={5}>{userDetails.username}</Title>
            </div>
          </div>
          <div>
            <Tooltip title="Refresh">
              <Button
                type="link"
                loading={refreshing}
                icon={<SyncOutlined />}
                onClick={refreshUserDetails}
              />
            </Tooltip>
          </div>
        </div>

        <Divider style={{ margin: 0 }} />

        <div className="user_details_pop_wrapper">
          <div>
            <Title level={5}>
              <UsergroupAddOutlined /> Affiliate
            </Title>
          </div>

          <div>
            <Title type="secondary" copyable level={5}>
              <Link
                href={`https://deviceforensic.com/register?affiliateCode=${userDetails.promoCode}`}
                target="_blank"
              >
                {userDetails.promoCode}
              </Link>
            </Title>
          </div>
        </div>

        {viewCredit && (
          <div className="user_details_pop_wrapper">
            <div>
              <Title level={5}>
                <DollarOutlined /> Credit
              </Title>
            </div>

            <div>
              <Title type="secondary" level={5}>
                {userDetails.credit} $
              </Title>
            </div>
          </div>
        )}

        <div className="user_details_pop_wrapper">
          <div>
            <Title level={5}>
              <ControlOutlined /> Role
            </Title>
          </div>

          <div>
            <Title type="secondary" level={5}>
              {userDetails.role}
            </Title>
          </div>
        </div>

        <div className="user_details_pop_wrapper">
          <div>
            <Title level={5}>Dark Theme</Title>
          </div>
          <div>
            <Switch
              onChange={handleSetTheme}
              checked={theme === "dark" ? true : false}
            />
          </div>
        </div>

        <Button
          danger
          type="primary"
          onClick={logout}
          style={{ width: "100%" }}
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>

        {userDetails.role !== "Sub" && (
          <Button
            type="primary"
            style={{ width: "100%" }}
            icon={<DollarOutlined />}
            onClick={() => setShowPayment(true)}
          >
            Purchase Credit
          </Button>
        )}
      </Space>
      <PaymentDailog show={showPayment} setShow={setShowPayment} />
    </>
  );
};

function mapStateToProps(state: any) {
  const userDetails = state.userDetails;
  const theme = state.configs.theme as "light" | "dark";
  return { theme, userDetails };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setTheme: (theme: string) => {
      localStorage.setItem("theme", theme);
      dispatch(setConfigs({ theme }));
    },
    refreshDetails: (details: UserDetails) => dispatch(setUserDetails(details)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPop);
