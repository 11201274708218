import PDFkit from "pdfkit";
import barCode from "bwip-js";
import { truncate } from "lodash";
import blobStream from "blob-stream";

import "../utils/register-pdfkit-files";
import AppLogo from "../assets/logo.0.png";

export interface LabelDataModel {
  result: any;
  serial: string;
}

/**
 * @param data label fields to be print
 * @description Generate pdf label for printing
 */
function generateLabel({
  label,
  serial,
  result,
}: {
  label: PDFKit.PDFDocument;
  result: any;
  serial: string;
}) {
  // return new Promise<void>(async (resolve, reject) => {
  /**
   * @description generate barcode
   */
  const canvas = document.createElement("canvas");
  const barCodeImg = barCode.toCanvas(canvas, {
    height: 6,
    textsize: 10,
    textyoffset: 5,
    bcid: "code128",
    monochrome: true,
    includetext: true,
    text: serial || result["IMEI"],
  });
  const qr = barCodeImg.toDataURL("image/png");

  const dataKeys = Object.keys(result);

  const labelData: [string, string][] = dataKeys.map((key) => [
    key,
    result[key],
  ]);

  label.addPage();

  label
    .image(AppLogo, {
      height: 22,
    })
    .moveUp()
    .fontSize(9)
    .text(new Date().toLocaleDateString(), 0, undefined, {
      align: "right",
      baseline: "top",
      characterSpacing: 1.1,
    })
    .moveDown()
    .lineCap("round")
    .moveTo(10, 34)
    .lineTo(182, 34)
    .lineWidth(1.5)
    .dash(5, { space: 3 })
    .stroke()
    .undash()
    .image(qr, 20, undefined, {
      height: 35,
      align: "center",
    })
    .moveDown(2)
    .lineJoin("round")
    .roundedRect(10, 78, 172, 20, 25)
    .stroke()
    .fontSize(10)
    .font("Helvetica-Bold")
    .moveUp()
    .text(
      truncate(result["Model"] ?? "unknown", { length: 24 }),
      10,
      undefined,
      {
        align: "center",
      }
    )
    .moveDown();

  addLabelData(labelData);

  function addLabelData(labelDataa: [string, string][]) {
    const printPoint = 15;

    labelDataa.forEach((eachData) => {
      const [key, value] = eachData;

      label
        .text(`${key}:`, printPoint, undefined, { align: "left" })
        .moveUp()
        .text(value, printPoint + 60, undefined, { align: "left" })
        .moveDown();
    });
  }
  // });
}

export default (data: LabelDataModel[]) => {
  return new Promise<string>(async (resolve, reject) => {
    const label = new PDFkit({
      margins: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
      size: [384, 192],
      layout: "landscape",
      autoFirstPage: false,
    });

    const stream = label.pipe(blobStream());

    stream.once("finish", function () {
      resolve(stream.toBlobURL("application/pdf"));
    });

    for (const _labelData of data) {
      generateLabel({
        label,
        serial: _labelData.serial,
        result: _labelData.result,
      });
    }

    label.end();
  });
};
