import React from "react";
import { Modal } from "antd";

import Payment from "../../Containers/Payment/Payment";

export interface PaymentDailogProps {
  show: boolean;
  invoiceId?: number;
  setShow: (view: boolean) => void;
}

const PaymentDailog: React.FunctionComponent<PaymentDailogProps> = ({
  show,
  setShow,
  invoiceId,
}) => (
  <Modal
    centered
    footer={null}
    destroyOnClose
    visible={show}
    onCancel={() => setShow(false)}
    title={invoiceId ? "Pay Invoice" : "Purchase Credit"}
  >
    <Payment closeDialog={() => setShow(false)} invoiceId={invoiceId} />
  </Modal>
);

export default PaymentDailog;
