import { Action } from "redux";

/**
 * @description Global Config redux action types
 */
export enum ConfigTypes {
  SetConfig = "SET_CONFIGS",
}

/**
 * @description Global app configs object type
 */
export interface Configs {
  sessionToken?: string;
  theme?: "light" | "dark";
}

/**
 * @description set app config redux action interface
 */
export interface ConfigAction extends Action {
  payload: Configs;
}

/**
 * @description set app configs redux action
 * @param configs Global app configs object
 */
export function setConfigs(configs: any) {
  return {
    payload: configs,
    type: ConfigTypes.SetConfig,
  } as ConfigAction;
}
