export default [
  {
    alpha2: "AO",
    flag: "🇦🇴",
    name: "Angola",
    code: "+244",
  },
  {
    alpha2: "KI",
    flag: "🇰🇮",
    name: "Kiribati",
    code: "+686",
  },
  {
    alpha2: "GG",
    flag: "🇬🇬",
    name: "Guernsey",
    code: "+44",
  },
  {
    alpha2: "GP",
    flag: "🇬🇵",
    name: "Guadeloupe",
    code: "+590",
  },
  {
    alpha2: "KM",
    flag: "🇰🇲",
    name: "Comoros",
    code: "+269",
  },
  {
    alpha2: "TC",
    flag: "🇹🇨",
    name: "Turks and Caicos Islands",
    code: "+1649",
  },
  {
    alpha2: "KY",
    flag: "🇰🇾",
    name: "Cayman Islands",
    code: "+1345",
  },
  {
    alpha2: "IL",
    flag: "🇮🇱",
    name: "Israel",
    code: "+972",
  },
  {
    alpha2: "SN",
    flag: "🇸🇳",
    name: "Senegal",
    code: "+221",
  },
  {
    alpha2: "SS",
    flag: "🇸🇸",
    name: "South Sudan",
    code: "+211",
  },
  {
    alpha2: "AX",
    flag: "🇦🇽",
    name: "Åland Islands",
    code: "+35818",
  },
  {
    alpha2: "NZ",
    flag: "🇳🇿",
    name: "New Zealand",
    code: "+64",
  },
  {
    alpha2: "PS",
    flag: "🇵🇸",
    name: "Palestine",
    code: "+970",
  },
  {
    alpha2: "LR",
    flag: "🇱🇷",
    name: "Liberia",
    code: "+231",
  },
  {
    alpha2: "SB",
    flag: "🇸🇧",
    name: "Solomon Islands",
    code: "+677",
  },
  {
    alpha2: "SI",
    flag: "🇸🇮",
    name: "Slovenia",
    code: "+386",
  },
  {
    alpha2: "TK",
    flag: "🇹🇰",
    name: "Tokelau",
    code: "+690",
  },
  {
    alpha2: "UZ",
    flag: "🇺🇿",
    name: "Uzbekistan",
    code: "+998",
  },
  {
    alpha2: "ZW",
    flag: "🇿🇼",
    name: "Zimbabwe",
    code: "+263",
  },
  {
    alpha2: "TG",
    flag: "🇹🇬",
    name: "Togo",
    code: "+228",
  },
  {
    alpha2: "CF",
    flag: "🇨🇫",
    name: "Central African Republic",
    code: "+236",
  },
  {
    alpha2: "AE",
    flag: "🇦🇪",
    name: "United Arab Emirates",
    code: "+971",
  },
  {
    alpha2: "ZM",
    flag: "🇿🇲",
    name: "Zambia",
    code: "+260",
  },
  {
    alpha2: "KH",
    flag: "🇰🇭",
    name: "Cambodia",
    code: "+855",
  },
  {
    alpha2: "VN",
    flag: "🇻🇳",
    name: "Vietnam",
    code: "+84",
  },
  {
    alpha2: "PN",
    flag: "🇵🇳",
    name: "Pitcairn Islands",
    code: "+64",
  },
  {
    alpha2: "TF",
    flag: "🇹🇫",
    name: "French Southern and Antarctic Lands",
    code: "+262",
  },
  {
    alpha2: "PF",
    flag: "🇵🇫",
    name: "French Polynesia",
    code: "+689",
  },
  {
    alpha2: "ZA",
    flag: "🇿🇦",
    name: "South Africa",
    code: "+27",
  },
  {
    alpha2: "RE",
    flag: "🇷🇪",
    name: "Réunion",
    code: "+262",
  },
  {
    alpha2: "AU",
    flag: "🇦🇺",
    name: "Australia",
    code: "+61",
  },
  {
    alpha2: "FI",
    flag: "🇫🇮",
    name: "Finland",
    code: "+358",
  },
  {
    alpha2: "GY",
    flag: "🇬🇾",
    name: "Guyana",
    code: "+592",
  },
  {
    alpha2: "MZ",
    flag: "🇲🇿",
    name: "Mozambique",
    code: "+258",
  },
  {
    alpha2: "TM",
    flag: "🇹🇲",
    name: "Turkmenistan",
    code: "+993",
  },
  {
    alpha2: "LA",
    flag: "🇱🇦",
    name: "Laos",
    code: "+856",
  },
  {
    alpha2: "HU",
    flag: "🇭🇺",
    name: "Hungary",
    code: "+36",
  },
  {
    alpha2: "VG",
    flag: "🇻🇬",
    name: "British Virgin Islands",
    code: "+1284",
  },
  {
    alpha2: "CG",
    flag: "🇨🇬",
    name: "Republic of the Congo",
    code: "+242",
  },
  {
    alpha2: "AR",
    flag: "🇦🇷",
    name: "Argentina",
    code: "+54",
  },
  {
    alpha2: "MT",
    flag: "🇲🇹",
    name: "Malta",
    code: "+356",
  },
  {
    alpha2: "TR",
    flag: "🇹🇷",
    name: "Turkey",
    code: "+90",
  },
  {
    alpha2: "HT",
    flag: "🇭🇹",
    name: "Haiti",
    code: "+509",
  },
  {
    alpha2: "GQ",
    flag: "🇬🇶",
    name: "Equatorial Guinea",
    code: "+240",
  },
  {
    alpha2: "GR",
    flag: "🇬🇷",
    name: "Greece",
    code: "+30",
  },
  {
    alpha2: "LT",
    flag: "🇱🇹",
    name: "Lithuania",
    code: "+370",
  },
  {
    alpha2: "SY",
    flag: "🇸🇾",
    name: "Syria",
    code: "+963",
  },
  {
    alpha2: "HM",
    flag: "🇭🇲",
    name: "Heard Island and McDonald Islands",
    code: "",
  },
  {
    alpha2: "SM",
    flag: "🇸🇲",
    name: "San Marino",
    code: "+378",
  },
  {
    alpha2: "SE",
    flag: "🇸🇪",
    name: "Sweden",
    code: "+46",
  },
  {
    alpha2: "BZ",
    flag: "🇧🇿",
    name: "Belize",
    code: "+501",
  },
  {
    alpha2: "UM",
    flag: "🇺🇲",
    name: "United States Minor Outlying Islands",
    code: "+268",
  },
  {
    alpha2: "CL",
    flag: "🇨🇱",
    name: "Chile",
    code: "+56",
  },
  {
    alpha2: "RS",
    flag: "🇷🇸",
    name: "Serbia",
    code: "+381",
  },
  {
    alpha2: "US",
    flag: "🇺🇸",
    name: "United States",
    code: "+1",
  },
  {
    alpha2: "DZ",
    flag: "🇩🇿",
    name: "Algeria",
    code: "+213",
  },
  {
    alpha2: "BO",
    flag: "🇧🇴",
    name: "Bolivia",
    code: "+591",
  },
  {
    alpha2: "JE",
    flag: "🇯🇪",
    name: "Jersey",
    code: "+44",
  },
  {
    alpha2: "CK",
    flag: "🇨🇰",
    name: "Cook Islands",
    code: "+682",
  },
  {
    alpha2: "VU",
    flag: "🇻🇺",
    name: "Vanuatu",
    code: "+678",
  },
  {
    alpha2: "NA",
    flag: "🇳🇦",
    name: "Namibia",
    code: "+264",
  },
  {
    alpha2: "SV",
    flag: "🇸🇻",
    name: "El Salvador",
    code: "+503",
  },
  {
    alpha2: "YE",
    flag: "🇾🇪",
    name: "Yemen",
    code: "+967",
  },
  {
    alpha2: "MO",
    flag: "🇲🇴",
    name: "Macau",
    code: "+853",
  },
  {
    alpha2: "MU",
    flag: "🇲🇺",
    name: "Mauritius",
    code: "+230",
  },
  {
    alpha2: "AW",
    flag: "🇦🇼",
    name: "Aruba",
    code: "+297",
  },
  {
    alpha2: "PR",
    flag: "🇵🇷",
    name: "Puerto Rico",
    code: "+1",
  },
  {
    alpha2: "KG",
    flag: "🇰🇬",
    name: "Kyrgyzstan",
    code: "+996",
  },
  {
    alpha2: "GT",
    flag: "🇬🇹",
    name: "Guatemala",
    code: "+502",
  },
  {
    alpha2: "NC",
    flag: "🇳🇨",
    name: "New Caledonia",
    code: "+687",
  },
  {
    alpha2: "TO",
    flag: "🇹🇴",
    name: "Tonga",
    code: "+676",
  },
  {
    alpha2: "GN",
    flag: "🇬🇳",
    name: "Guinea",
    code: "+224",
  },
  {
    alpha2: "AQ",
    flag: "🇦🇶",
    name: "Antarctica",
    code: "",
  },
  {
    alpha2: "TT",
    flag: "🇹🇹",
    name: "Trinidad and Tobago",
    code: "+1868",
  },
  {
    alpha2: "JM",
    flag: "🇯🇲",
    name: "Jamaica",
    code: "+1876",
  },
  {
    alpha2: "BS",
    flag: "🇧🇸",
    name: "Bahamas",
    code: "+1242",
  },
  {
    alpha2: "RU",
    flag: "🇷🇺",
    name: "Russia",
    code: "+7",
  },
  {
    alpha2: "LS",
    flag: "🇱🇸",
    name: "Lesotho",
    code: "+266",
  },
  {
    alpha2: "MM",
    flag: "🇲🇲",
    name: "Myanmar",
    code: "+95",
  },
  {
    alpha2: "BT",
    flag: "🇧🇹",
    name: "Bhutan",
    code: "+975",
  },
  {
    alpha2: "MR",
    flag: "🇲🇷",
    name: "Mauritania",
    code: "+222",
  },
  {
    alpha2: "CN",
    flag: "🇨🇳",
    name: "China",
    code: "+86",
  },
  {
    alpha2: "PY",
    flag: "🇵🇾",
    name: "Paraguay",
    code: "+595",
  },
  {
    alpha2: "GL",
    flag: "🇬🇱",
    name: "Greenland",
    code: "+299",
  },
  {
    alpha2: "FO",
    flag: "🇫🇴",
    name: "Faroe Islands",
    code: "+298",
  },
  {
    alpha2: "SJ",
    flag: "🇸🇯",
    name: "Svalbard and Jan Mayen",
    code: "+4779",
  },
  {
    alpha2: "GH",
    flag: "🇬🇭",
    name: "Ghana",
    code: "+233",
  },
  {
    alpha2: "MW",
    flag: "🇲🇼",
    name: "Malawi",
    code: "+265",
  },
  {
    alpha2: "PG",
    flag: "🇵🇬",
    name: "Papua New Guinea",
    code: "+675",
  },
  {
    alpha2: "FR",
    flag: "🇫🇷",
    name: "France",
    code: "+33",
  },
  {
    alpha2: "SZ",
    flag: "🇸🇿",
    name: "Eswatini",
    code: "+268",
  },
  {
    alpha2: "JO",
    flag: "🇯🇴",
    name: "Jordan",
    code: "+962",
  },
  {
    alpha2: "CA",
    flag: "🇨🇦",
    name: "Canada",
    code: "+1",
  },
  {
    alpha2: "SH",
    flag: "🇸🇭",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "+2",
  },
  {
    alpha2: "MA",
    flag: "🇲🇦",
    name: "Morocco",
    code: "+212",
  },
  {
    alpha2: "MN",
    flag: "🇲🇳",
    name: "Mongolia",
    code: "+976",
  },
  {
    alpha2: "NR",
    flag: "🇳🇷",
    name: "Nauru",
    code: "+674",
  },
  {
    alpha2: "IS",
    flag: "🇮🇸",
    name: "Iceland",
    code: "+354",
  },
  {
    alpha2: "LU",
    flag: "🇱🇺",
    name: "Luxembourg",
    code: "+352",
  },
  {
    alpha2: "TH",
    flag: "🇹🇭",
    name: "Thailand",
    code: "+66",
  },
  {
    alpha2: "AD",
    flag: "🇦🇩",
    name: "Andorra",
    code: "+376",
  },
  {
    alpha2: "BG",
    flag: "🇧🇬",
    name: "Bulgaria",
    code: "+359",
  },
  {
    alpha2: "NI",
    flag: "🇳🇮",
    name: "Nicaragua",
    code: "+505",
  },
  {
    alpha2: "IT",
    flag: "🇮🇹",
    name: "Italy",
    code: "+39",
  },
  {
    alpha2: "BL",
    flag: "🇧🇱",
    name: "Saint Barthélemy",
    code: "+590",
  },
  {
    alpha2: "AS",
    flag: "🇦🇸",
    name: "American Samoa",
    code: "+1684",
  },
  {
    alpha2: "CI",
    flag: "🇨🇮",
    name: "Ivory Coast",
    code: "+225",
  },
  {
    alpha2: "PW",
    flag: "🇵🇼",
    name: "Palau",
    code: "+680",
  },
  {
    alpha2: "GU",
    flag: "🇬🇺",
    name: "Guam",
    code: "+1671",
  },
  {
    alpha2: "GI",
    flag: "🇬🇮",
    name: "Gibraltar",
    code: "+350",
  },
  {
    alpha2: "AI",
    flag: "🇦🇮",
    name: "Anguilla",
    code: "+1264",
  },
  {
    alpha2: "CO",
    flag: "🇨🇴",
    name: "Colombia",
    code: "+57",
  },
  {
    alpha2: "TD",
    flag: "🇹🇩",
    name: "Chad",
    code: "+235",
  },
  {
    alpha2: "BR",
    flag: "🇧🇷",
    name: "Brazil",
    code: "+55",
  },
  {
    alpha2: "NP",
    flag: "🇳🇵",
    name: "Nepal",
    code: "+977",
  },
  {
    alpha2: "IM",
    flag: "🇮🇲",
    name: "Isle of Man",
    code: "+44",
  },
  {
    alpha2: "GF",
    flag: "🇬🇫",
    name: "French Guiana",
    code: "+594",
  },
  {
    alpha2: "VC",
    flag: "🇻🇨",
    name: "Saint Vincent and the Grenadines",
    code: "+1784",
  },
  {
    alpha2: "KP",
    flag: "🇰🇵",
    name: "North Korea",
    code: "+850",
  },
  {
    alpha2: "SX",
    flag: "🇸🇽",
    name: "Sint Maarten",
    code: "+1721",
  },
  {
    alpha2: "SR",
    flag: "🇸🇷",
    name: "Suriname",
    code: "+597",
  },
  {
    alpha2: "KW",
    flag: "🇰🇼",
    name: "Kuwait",
    code: "+965",
  },
  {
    alpha2: "SO",
    flag: "🇸🇴",
    name: "Somalia",
    code: "+252",
  },
  {
    alpha2: "TV",
    flag: "🇹🇻",
    name: "Tuvalu",
    code: "+688",
  },
  {
    alpha2: "BQ",
    flag: "🇧🇶",
    name: "Caribbean Netherlands",
    code: "+599",
  },
  {
    alpha2: "CD",
    flag: "🇨🇩",
    name: "DR Congo",
    code: "+243",
  },
  {
    alpha2: "AL",
    flag: "🇦🇱",
    name: "Albania",
    code: "+355",
  },
  {
    alpha2: "HN",
    flag: "🇭🇳",
    name: "Honduras",
    code: "+504",
  },
  {
    alpha2: "EC",
    flag: "🇪🇨",
    name: "Ecuador",
    code: "+593",
  },
  {
    alpha2: "SD",
    flag: "🇸🇩",
    name: "Sudan",
    code: "+249",
  },
  {
    alpha2: "FM",
    flag: "🇫🇲",
    name: "Micronesia",
    code: "+691",
  },
  {
    alpha2: "HK",
    flag: "🇭🇰",
    name: "Hong Kong",
    code: "+852",
  },
  {
    alpha2: "BJ",
    flag: "🇧🇯",
    name: "Benin",
    code: "+229",
  },
  {
    alpha2: "EH",
    flag: "🇪🇭",
    name: "Western Sahara",
    code: "+2",
  },
  {
    alpha2: "LK",
    flag: "🇱🇰",
    name: "Sri Lanka",
    code: "+94",
  },
  {
    alpha2: "BD",
    flag: "🇧🇩",
    name: "Bangladesh",
    code: "+880",
  },
  {
    alpha2: "MG",
    flag: "🇲🇬",
    name: "Madagascar",
    code: "+261",
  },
  {
    alpha2: "BH",
    flag: "🇧🇭",
    name: "Bahrain",
    code: "+973",
  },
  {
    alpha2: "LI",
    flag: "🇱🇮",
    name: "Liechtenstein",
    code: "+423",
  },
  {
    alpha2: "MK",
    flag: "🇲🇰",
    name: "North Macedonia",
    code: "+389",
  },
  {
    alpha2: "CX",
    flag: "🇨🇽",
    name: "Christmas Island",
    code: "+61",
  },
  {
    alpha2: "UA",
    flag: "🇺🇦",
    name: "Ukraine",
    code: "+380",
  },
  {
    alpha2: "EG",
    flag: "🇪🇬",
    name: "Egypt",
    code: "+20",
  },
  {
    alpha2: "ER",
    flag: "🇪🇷",
    name: "Eritrea",
    code: "+291",
  },
  {
    alpha2: "MP",
    flag: "🇲🇵",
    name: "Northern Mariana Islands",
    code: "+1670",
  },
  {
    alpha2: "AZ",
    flag: "🇦🇿",
    name: "Azerbaijan",
    code: "+994",
  },
  {
    alpha2: "NE",
    flag: "🇳🇪",
    name: "Niger",
    code: "+227",
  },
  {
    alpha2: "LC",
    flag: "🇱🇨",
    name: "Saint Lucia",
    code: "+1758",
  },
  {
    alpha2: "MS",
    flag: "🇲🇸",
    name: "Montserrat",
    code: "+1664",
  },
  {
    alpha2: "VI",
    flag: "🇻🇮",
    name: "United States Virgin Islands",
    code: "+1340",
  },
  {
    alpha2: "SL",
    flag: "🇸🇱",
    name: "Sierra Leone",
    code: "+232",
  },
  {
    alpha2: "DJ",
    flag: "🇩🇯",
    name: "Djibouti",
    code: "+253",
  },
  {
    alpha2: "HR",
    flag: "🇭🇷",
    name: "Croatia",
    code: "+385",
  },
  {
    alpha2: "NO",
    flag: "🇳🇴",
    name: "Norway",
    code: "+47",
  },
  {
    alpha2: "DM",
    flag: "🇩🇲",
    name: "Dominica",
    code: "+1767",
  },
  {
    alpha2: "UY",
    flag: "🇺🇾",
    name: "Uruguay",
    code: "+598",
  },
  {
    alpha2: "GM",
    flag: "🇬🇲",
    name: "Gambia",
    code: "+220",
  },
  {
    alpha2: "MD",
    flag: "🇲🇩",
    name: "Moldova",
    code: "+373",
  },
  {
    alpha2: "AF",
    flag: "🇦🇫",
    name: "Afghanistan",
    code: "+93",
  },
  {
    alpha2: "PT",
    flag: "🇵🇹",
    name: "Portugal",
    code: "+351",
  },
  {
    alpha2: "ME",
    flag: "🇲🇪",
    name: "Montenegro",
    code: "+382",
  },
  {
    alpha2: "BN",
    flag: "🇧🇳",
    name: "Brunei",
    code: "+673",
  },
  {
    alpha2: "FJ",
    flag: "🇫🇯",
    name: "Fiji",
    code: "+679",
  },
  {
    alpha2: "MX",
    flag: "🇲🇽",
    name: "Mexico",
    code: "+52",
  },
  {
    alpha2: "CH",
    flag: "🇨🇭",
    name: "Switzerland",
    code: "+41",
  },
  {
    alpha2: "RW",
    flag: "🇷🇼",
    name: "Rwanda",
    code: "+250",
  },
  {
    alpha2: "CY",
    flag: "🇨🇾",
    name: "Cyprus",
    code: "+357",
  },
  {
    alpha2: "BM",
    flag: "🇧🇲",
    name: "Bermuda",
    code: "+1441",
  },
  {
    alpha2: "TW",
    flag: "🇹🇼",
    name: "Taiwan",
    code: "+886",
  },
  {
    alpha2: "JP",
    flag: "🇯🇵",
    name: "Japan",
    code: "+81",
  },
  {
    alpha2: "QA",
    flag: "🇶🇦",
    name: "Qatar",
    code: "+974",
  },
  {
    alpha2: "DK",
    flag: "🇩🇰",
    name: "Denmark",
    code: "+45",
  },
  {
    alpha2: "CC",
    flag: "🇨🇨",
    name: "Cocos (Keeling) Islands",
    code: "+61",
  },
  {
    alpha2: "BV",
    flag: "🇧🇻",
    name: "Bouvet Island",
    code: "+47",
  },
  {
    alpha2: "BW",
    flag: "🇧🇼",
    name: "Botswana",
    code: "+267",
  },
  {
    alpha2: "MC",
    flag: "🇲🇨",
    name: "Monaco",
    code: "+377",
  },
  {
    alpha2: "LB",
    flag: "🇱🇧",
    name: "Lebanon",
    code: "+961",
  },
  {
    alpha2: "LY",
    flag: "🇱🇾",
    name: "Libya",
    code: "+218",
  },
  {
    alpha2: "MY",
    flag: "🇲🇾",
    name: "Malaysia",
    code: "+60",
  },
  {
    alpha2: "VA",
    flag: "🇻🇦",
    name: "Vatican City",
    code: "+3",
  },
  {
    alpha2: "CR",
    flag: "🇨🇷",
    name: "Costa Rica",
    code: "+506",
  },
  {
    alpha2: "ES",
    flag: "🇪🇸",
    name: "Spain",
    code: "+34",
  },
  {
    alpha2: "MQ",
    flag: "🇲🇶",
    name: "Martinique",
    code: "+596",
  },
  {
    alpha2: "SK",
    flag: "🇸🇰",
    name: "Slovakia",
    code: "+421",
  },
  {
    alpha2: "BA",
    flag: "🇧🇦",
    name: "Bosnia and Herzegovina",
    code: "+387",
  },
  {
    alpha2: "BI",
    flag: "🇧🇮",
    name: "Burundi",
    code: "+257",
  },
  {
    alpha2: "CV",
    flag: "🇨🇻",
    name: "Cape Verde",
    code: "+238",
  },
  {
    alpha2: "OM",
    flag: "🇴🇲",
    name: "Oman",
    code: "+968",
  },
  {
    alpha2: "BY",
    flag: "🇧🇾",
    name: "Belarus",
    code: "+375",
  },
  {
    alpha2: "MF",
    flag: "🇲🇫",
    name: "Saint Martin",
    code: "+590",
  },
  {
    alpha2: "BB",
    flag: "🇧🇧",
    name: "Barbados",
    code: "+1246",
  },
  {
    alpha2: "XK",
    flag: "🇽🇰",
    name: "Kosovo",
    code: "+383",
  },
  {
    alpha2: "TZ",
    flag: "🇹🇿",
    name: "Tanzania",
    code: "+255",
  },
  {
    alpha2: "WS",
    flag: "🇼🇸",
    name: "Samoa",
    code: "+685",
  },
  {
    alpha2: "ET",
    flag: "🇪🇹",
    name: "Ethiopia",
    code: "+251",
  },
  {
    alpha2: "IN",
    flag: "🇮🇳",
    name: "India",
    code: "+91",
  },
  {
    alpha2: "EE",
    flag: "🇪🇪",
    name: "Estonia",
    code: "+372",
  },
  {
    alpha2: "IE",
    flag: "🇮🇪",
    name: "Ireland",
    code: "+353",
  },
  {
    alpha2: "MV",
    flag: "🇲🇻",
    name: "Maldives",
    code: "+960",
  },
  {
    alpha2: "SC",
    flag: "🇸🇨",
    name: "Seychelles",
    code: "+248",
  },
  {
    alpha2: "UG",
    flag: "🇺🇬",
    name: "Uganda",
    code: "+256",
  },
  {
    alpha2: "FK",
    flag: "🇫🇰",
    name: "Falkland Islands",
    code: "+500",
  },
  {
    alpha2: "ST",
    flag: "🇸🇹",
    name: "São Tomé and Príncipe",
    code: "+239",
  },
  {
    alpha2: "NU",
    flag: "🇳🇺",
    name: "Niue",
    code: "+683",
  },
  {
    alpha2: "TL",
    flag: "🇹🇱",
    name: "Timor-Leste",
    code: "+670",
  },
  {
    alpha2: "IQ",
    flag: "🇮🇶",
    name: "Iraq",
    code: "+964",
  },
  {
    alpha2: "KN",
    flag: "🇰🇳",
    name: "Saint Kitts and Nevis",
    code: "+1869",
  },
  {
    alpha2: "CW",
    flag: "🇨🇼",
    name: "Curaçao",
    code: "+599",
  },
  {
    alpha2: "NF",
    flag: "🇳🇫",
    name: "Norfolk Island",
    code: "+672",
  },
  {
    alpha2: "MH",
    flag: "🇲🇭",
    name: "Marshall Islands",
    code: "+692",
  },
  {
    alpha2: "ML",
    flag: "🇲🇱",
    name: "Mali",
    code: "+223",
  },
  {
    alpha2: "GW",
    flag: "🇬🇼",
    name: "Guinea-Bissau",
    code: "+245",
  },
  {
    alpha2: "YT",
    flag: "🇾🇹",
    name: "Mayotte",
    code: "+262",
  },
  {
    alpha2: "PL",
    flag: "🇵🇱",
    name: "Poland",
    code: "+48",
  },
  {
    alpha2: "TJ",
    flag: "🇹🇯",
    name: "Tajikistan",
    code: "+992",
  },
  {
    alpha2: "GB",
    flag: "🇬🇧",
    name: "United Kingdom",
    code: "+44",
  },
  {
    alpha2: "KE",
    flag: "🇰🇪",
    name: "Kenya",
    code: "+254",
  },
  {
    alpha2: "KZ",
    flag: "🇰🇿",
    name: "Kazakhstan",
    code: "+7",
  },
  {
    alpha2: "PA",
    flag: "🇵🇦",
    name: "Panama",
    code: "+507",
  },
  {
    alpha2: "SG",
    flag: "🇸🇬",
    name: "Singapore",
    code: "+65",
  },
  {
    alpha2: "NG",
    flag: "🇳🇬",
    name: "Nigeria",
    code: "+234",
  },
  {
    alpha2: "RO",
    flag: "🇷🇴",
    name: "Romania",
    code: "+40",
  },
  {
    alpha2: "AG",
    flag: "🇦🇬",
    name: "Antigua and Barbuda",
    code: "+1268",
  },
  {
    alpha2: "CZ",
    flag: "🇨🇿",
    name: "Czechia",
    code: "+420",
  },
  {
    alpha2: "AM",
    flag: "🇦🇲",
    name: "Armenia",
    code: "+374",
  },
  {
    alpha2: "VE",
    flag: "🇻🇪",
    name: "Venezuela",
    code: "+58",
  },
  {
    alpha2: "PH",
    flag: "🇵🇭",
    name: "Philippines",
    code: "+63",
  },
  {
    alpha2: "WF",
    flag: "🇼🇫",
    name: "Wallis and Futuna",
    code: "+681",
  },
  {
    alpha2: "DE",
    flag: "🇩🇪",
    name: "Germany",
    code: "+49",
  },
  {
    alpha2: "PE",
    flag: "🇵🇪",
    name: "Peru",
    code: "+51",
  },
  {
    alpha2: "CM",
    flag: "🇨🇲",
    name: "Cameroon",
    code: "+237",
  },
  {
    alpha2: "IO",
    flag: "🇮🇴",
    name: "British Indian Ocean Territory",
    code: "+246",
  },
  {
    alpha2: "KR",
    flag: "🇰🇷",
    name: "South Korea",
    code: "+82",
  },
  {
    alpha2: "PM",
    flag: "🇵🇲",
    name: "Saint Pierre and Miquelon",
    code: "+508",
  },
  {
    alpha2: "GA",
    flag: "🇬🇦",
    name: "Gabon",
    code: "+241",
  },
  {
    alpha2: "DO",
    flag: "🇩🇴",
    name: "Dominican Republic",
    code: "+1",
  },
  {
    alpha2: "LV",
    flag: "🇱🇻",
    name: "Latvia",
    code: "+371",
  },
  {
    alpha2: "IR",
    flag: "🇮🇷",
    name: "Iran",
    code: "+98",
  },
  {
    alpha2: "CU",
    flag: "🇨🇺",
    name: "Cuba",
    code: "+53",
  },
  {
    alpha2: "SA",
    flag: "🇸🇦",
    name: "Saudi Arabia",
    code: "+966",
  },
  {
    alpha2: "GE",
    flag: "🇬🇪",
    name: "Georgia",
    code: "+995",
  },
  {
    alpha2: "PK",
    flag: "🇵🇰",
    name: "Pakistan",
    code: "+92",
  },
  {
    alpha2: "BE",
    flag: "🇧🇪",
    name: "Belgium",
    code: "+32",
  },
  {
    alpha2: "GS",
    flag: "🇬🇸",
    name: "South Georgia",
    code: "+500",
  },
  {
    alpha2: "TN",
    flag: "🇹🇳",
    name: "Tunisia",
    code: "+216",
  },
  {
    alpha2: "NL",
    flag: "🇳🇱",
    name: "Netherlands",
    code: "+31",
  },
  {
    alpha2: "BF",
    flag: "🇧🇫",
    name: "Burkina Faso",
    code: "+226",
  },
  {
    alpha2: "ID",
    flag: "🇮🇩",
    name: "Indonesia",
    code: "+62",
  },
  {
    alpha2: "AT",
    flag: "🇦🇹",
    name: "Austria",
    code: "+43",
  },
  {
    alpha2: "GD",
    flag: "🇬🇩",
    name: "Grenada",
    code: "+1473",
  },
];
