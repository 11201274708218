import {
  Badge,
  Modal,
  Table,
  Button,
  message,
  Tooltip,
  Typography,
  Pagination,
  Descriptions,
} from "antd";
import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";

import CuPriceGroup from "./CuPriceGroup";
import httpClient from "../../../common/httpClient";

export interface PriceGroupsProps {}

const { Title, Link } = Typography;

const PriceGroups: React.FunctionComponent<PriceGroupsProps> = () => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [priceGroups, setPriceGroups] = useState<any[]>([]);
  const [editPriceGroup, setEditPriceGroup] = useState<any | undefined>();

  useEffect(() => {
    fetchPriceGroups();
  }, [page, size]);

  const columns: ColumnsType = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
    },
    {
      key: "discount",
      title: "Discount",
      dataIndex: "discount",
      render: (txt: any) => `${txt}%`,
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "",
      render: (_, record: any) => (
        <Link
          onClick={() => {
            setEditPriceGroup(record);
            setShowAdd(true);
          }}
        >
          Edit
        </Link>
      ),
    },
  ];

  /**
   * @description fetch user price groups from remote api
   */
  async function fetchPriceGroups() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/user/price-group", {
        size: size,
        page: page - 1,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setTotal(data.total);

      const _priceGroups = data.groups.map((batch: any, index: number) => {
        batch["key"] = index;
        return batch;
      });

      setPriceGroups(_priceGroups);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  return (
    <>
      <div className="bach_preview_head_title_wrapper">
        <div>
          <Title level={5}>Price Groups</Title>
        </div>
        <div>
          <Tooltip title="Refresh">
            <Button
              type="link"
              loading={loading}
              disabled={!!!total}
              icon={<SyncOutlined />}
              onClick={fetchPriceGroups}
            />
          </Tooltip>
        </div>
      </div>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setEditPriceGroup(undefined);
          setShowAdd(true);
        }}
        style={{ width: "100%", marginBottom: "10px" }}
      >
        Add Price Group
      </Button>

      <Descriptions
        bordered
        size="small"
        column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        <Descriptions.Item
          label={<Badge text="Total" status="default" color="blue" />}
        >
          {total}
        </Descriptions.Item>
      </Descriptions>

      <Table
        sticky
        size="small"
        loading={loading}
        pagination={false}
        dataSource={priceGroups}
        columns={columns as any}
        className="price_group_table"
      />
      <div className="pagination">
        <Pagination
          size="small"
          total={total}
          current={page}
          pageSize={size}
          showSizeChanger
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>
      <Modal
        centered
        footer={null}
        destroyOnClose
        visible={showAdd}
        maskClosable={false}
        title={`${editPriceGroup ? "Update" : "Create"} Price Group`}
        onCancel={() => {
          setShowAdd(false);
          setEditPriceGroup(undefined);
        }}
      >
        <CuPriceGroup
          setShow={setShowAdd}
          refresh={fetchPriceGroups}
          priceGroup={editPriceGroup}
        />
      </Modal>
    </>
  );
};

export default PriceGroups;
