import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Input, message, Row } from "antd";

import httpClient from "../../../../common/httpClient";

export interface ServiceGroupModalProps {
  refresh: () => void;
  setShow: (show: boolean) => void;
  editGroup?: { id: number; title: string };
}

const ServiceGroupModal: React.FunctionComponent<ServiceGroupModalProps> = ({
  refresh,
  setShow,
  editGroup,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values: any) {
    setLoading(true);

    try {
      let res: AxiosResponse;
      if (!!editGroup) {
        res = await httpClient.patch("/service/group", {
          ...values,
          id: editGroup!.id,
        });
      } else {
        res = await httpClient.post("/service/group", { ...values });
      }

      const { data } = res;

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      refresh();
      setShow(false);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
        initialValues={{ title: editGroup?.title ?? "" }}
      >
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Form.Item name="title" label="Title" tooltip="Group title">
              <Input placeholder="Apple Group" />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            {editGroup ? "Update" : "Create"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ServiceGroupModal;
