import { truncate } from "lodash";
import { useStore } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, message, Typography } from "antd";
import { ApiOutlined, PlusOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";

export interface AdminApiKeysProps {
  username: string;
}

const { Text, Title } = Typography;

const ApiKeys: React.FunctionComponent<AdminApiKeysProps> = ({ username }) => {
  const store = useStore();
  const [apikeys, setApikeys] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  /**
   * @description fetch api keys from remote API
   */
  async function fetchApiKeys() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/user/apikey/admin", { username });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setApikeys(data.apiKeys);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description generate new api key
   */
  async function generateAPIKey() {
    setLoading(true);
    try {
      const { data } = await httpClient.post("/user/apikey/admin", {
        username,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("API key created.");
      await fetchApiKeys();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Title level={4}>
            API Keys <ApiOutlined />
          </Title>
        </div>
        <div>
          <Button type="primary" loading={loading} onClick={generateAPIKey}>
            <PlusOutlined />{" "}
            {!!apikeys.length ? "Regenerate Key" : "Generate API Key"}
          </Button>
        </div>
      </div>

      {apikeys.length > 0 && (
        <div>
          <Text copyable={{ text: apikeys[0].apiKey }}>
            {truncate(apikeys[0].apiKey, {
              length: 34,
              omission: "-****-****-****-************",
            })}
          </Text>
        </div>
      )}
    </>
  );
};

export default ApiKeys;
