import {
  Table,
  Badge,
  Space,
  Button,
  Tooltip,
  message,
  DatePicker,
  Typography,
  Pagination,
  Descriptions,
} from "antd";
import moment from "moment";
import { useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { SyncOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";

import "./Statement.scss";
import httpClient from "../../common/httpClient";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export interface StatementsProps {}

const Statements: React.FunctionComponent<StatementsProps> = () => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [total, setTotal] = useState("");
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState();
  const [selRows, setSelRows] = useState<any[]>([]);
  const [toDate, setToDate] = useState<string | null>();
  const [fromDate, setFromDate] = useState<string | null>();
  const [toDateM, setToDateM] = useState<moment.Moment | null>();
  const [fromDateM, setFromDateM] = useState<moment.Moment | null>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const columns: ColumnsType = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sorter: {
        compare: (a: any, b: any, ord) => {
          switch (ord) {
            case "ascend":
              return a.id - b.id;
            case "descend":
              return b.id - a.id;
            default:
              return b.id - a.id;
          }
        },
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (txt: string) =>
        new Date(txt).toLocaleString("en-US", { timeZone: "America/Virgin" }),
    },
    { title: "Balance", dataIndex: "balance", key: "balance" },
    { title: "Credit", width: "80px", dataIndex: "credit", key: "credit" },
    { title: "Debit", dataIndex: "debit", key: "debit" },
    { title: "IP", dataIndex: "ip", key: "ip" },
    {
      width: "200px",
      key: "description",
      title: "Description",
      dataIndex: "description",
      // render: (txt: string) =>
      //   txt.length > 40 ? (
      //     <Tooltip title={truncate(txt, { length: 40 })} />
      //   ) : (
      //     txt
      //   ),
    },
  ];

  /**
   * @description handle search effect
   */
  useEffect(() => {
    fetchStatements();
  }, [page, size]);

  /**
   * @description fetch batches from remote api
   */
  async function fetchStatements() {
    if (!!!store.getState().configs.sessionToken) return;

    setLoading(true);
    try {
      const { data } = await httpClient.get("/statement", {
        size: size,
        page: page - 1,
        to: toDateM ? new Date(toDate!).toISOString() : null,
        from: fromDateM ? new Date(fromDate!).toISOString() : null,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setTotal(data.total);

      const _statements = data.statements.map(
        (statement: any, index: number) => {
          statement["key"] = index;
          return statement;
        }
      );

      setStatements(_statements);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  function handleDateChange(
    date: [moment.Moment, moment.Moment],
    dateString: [string, string]
  ) {
    if (date) {
      setToDate(dateString[1]);
      setFromDate(dateString[0]);
      setToDateM(date[1]);
      setFromDateM(date[0]);
    } else {
      setToDate(null);
      setFromDate(null);
      setToDateM(null);
      setFromDateM(null);
    }
  }

  return (
    <>
      <div className="bach_preview_head_title_wrapper">
        <div>
          <Title level={5}>Statements</Title>
        </div>

        <div>
          <Tooltip title="Refresh">
            <Button
              type="link"
              loading={loading}
              disabled={!!!total}
              icon={<SyncOutlined />}
              onClick={fetchStatements}
            />
          </Tooltip>
        </div>
      </div>

      <div
        style={
          !isMobile
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }
            : undefined
        }
      >
        <div style={{ width: isMobile ? "100%" : "50%" }}>
          <Descriptions bordered size="small">
            <Descriptions.Item
              label={<Badge text="Total" status="default" color="blue" />}
            >
              {total}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div className="statement_filter_wrapper">
          <Space>
            <RangePicker
              allowClear
              size="small"
              allowEmpty={[true, true]}
              onChange={handleDateChange as any}
              value={[fromDateM ?? null, toDateM ?? null]}
            />

            <Button
              size="small"
              type="primary"
              style={{ width: "100%" }}
              onClick={fetchStatements}
            >
              Filter
            </Button>
          </Space>
        </div>
      </div>

      <Table
        sticky
        size="small"
        loading={loading}
        pagination={false}
        scroll={{ x: true }}
        dataSource={statements}
        columns={columns as any}
        className="statement_table"
        rowSelection={{
          selectedRowKeys: selRows.map((row) => row.key),
          onChange: (keys, selRowss) => setSelRows(selRowss),
        }}
      />

      <div className="pagination">
        <Pagination
          size="small"
          current={page}
          pageSize={size}
          showSizeChanger
          total={parseInt(total)}
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>
    </>
  );
};

export default Statements;
