import React, { useState } from "react";
import { Button, Modal, Typography } from "antd";
import { PieChartOutlined } from "@ant-design/icons";

import Analytics from "../../Analytics/Analytics";

export interface ResetPasswordProps {
  username: string;
}

const { Title } = Typography;

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({
  username,
}) => {
  const [showAnalytics, setShowAnalytics] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Title level={4}>
            Analytics <PieChartOutlined />
          </Title>
        </div>
        <div>
          <Button type="primary" onClick={() => setShowAnalytics(true)}>
            Show
          </Button>
        </div>
      </div>

      <Modal
        centered
        width="1200px"
        footer={false}
        destroyOnClose
        title="Analytics"
        maskClosable={false}
        visible={showAnalytics}
        onCancel={() => setShowAnalytics(false)}
      >
        <Analytics username={username} />
      </Modal>
    </>
  );
};

export default ResetPassword;
