import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  BankOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import Reaptcha from "reaptcha";
import { connect } from "react-redux";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Image, message, Select, Space } from "antd";

import "./Register.scss";
import * as form from "./form";
import countries from "./countries";
import httpClient from "../../common/httpClient";

export interface RegisterProps {
  theme: "light" | "dark";
}

const Register: React.FunctionComponent<RegisterProps> = ({ theme }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState<string>();
  const [searchCountry, setSearchCountry] = useState("");
  const [reCaptcha, setRecaptcha] = useState<Reaptcha>();

  async function registerHandler(values: form.RegisterFormData) {
    setLoading(true);
    try {
      values.recaptcha = captcha!;
      values.promoCode = values.promoCode ? values.promoCode : undefined;
      values.phone = values.code + values.phone;
      console.log(values);
      const { data, status } = await httpClient.post("/signup", values);
      if (data.error || status !== 201) {
        message.error(data.message);
        return;
      }

      message.success("Account created, Please login.");
      history.replace("/login");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setCaptcha("");
      reCaptcha!.reset();
      setLoading(false);
    }
  }

  return (
    <div className="main_wrapper">
      <div className="inner_wrapper">
        <div className="logo-wrapper">
          <Image
            alt="Logo"
            width={150}
            preview={false}
            className="logo"
            src={
              process.env.PUBLIC_URL +
              `/assets/logo.0.${theme}.png`
            }
          />
        </div>

        <Form
          name="register"
          onFinish={registerHandler}
          initialValues={form.initRegisterValues}
        >
          <Form.Item name="firstName" rules={form.rules.firstName}>
            <Input prefix={<UserOutlined />} placeholder="First Name" />
          </Form.Item>
          <Form.Item name="lastName" rules={form.rules.lastName}>
            <Input prefix={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>
          <Form.Item name="username" rules={form.rules.username}>
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>

          <Space direction="horizontal">
            <Form.Item name="code" rules={form.rules.phone}>
              <Select
                style={{ width: "100px" }}
                showSearch
                placeholder="code"
                allowClear
              >
                {countries.map((c) => (
                  <Select.Option value={c.code}>
                    {c.flag + " " + c.code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="phone" rules={form.rules.phone}>
              <Input prefix={<PhoneOutlined />} placeholder="Phone number" />
            </Form.Item>
          </Space>

          <Form.Item name="country" rules={form.rules.country}>
            <Select
              filterOption={false}
              onSearch={(s) => setSearchCountry(s)}
              showSearch
              placeholder="Country"
              allowClear
            >
              {countries
                .filter((s) =>
                  s.name.toUpperCase().startsWith(searchCountry.toUpperCase())
                )
                .map((c) => (
                  <Select.Option value={c.alpha2}>
                    {c.flag + " " + c.name}
                  </Select.Option>
                ))}
            </Select>
            {/* <Input prefix={<FlagOutlined />} placeholder="Country" /> */}
          </Form.Item>
          <Form.Item name="company" rules={form.rules.company}>
            <Input prefix={<BankOutlined />} placeholder="Company" />
          </Form.Item>
          <Form.Item name="email" rules={form.rules.email as any}>
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item name="password" rules={form.rules.password}>
            <Input.Password placeholder="Password" prefix={<LockOutlined />} />
          </Form.Item>

          <Form.Item name="promoCode" rules={form.rules.promoCode}>
            <Input
              placeholder="Affiliate Code"
              prefix={<UsergroupAddOutlined />}
            />
          </Form.Item>

          <Form.Item name="recaptcha">
            <Reaptcha
              theme={theme}
              onVerify={setCaptcha}
              ref={(e) => setRecaptcha(e!)}
              sitekey="6Ld0MQ4fAAAAAELCDsUpu968y7_bPVps-rLoYRu_"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Signup
            </Button>
            &nbsp; Or <Link to="/login">login now!</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const theme = state.configs.theme;
  return {
    theme,
  };
}

export default connect(mapStateToProps, null)(Register);
