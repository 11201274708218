import React from "react";
import { Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons/lib/icons";

export interface OrdersLoaderProps {}

const { Title } = Typography;

const OrdersLoader: React.FunctionComponent<OrdersLoaderProps> = () => {
  return (
    <div className="placing_orders">
      <div style={{ marginRight: "10px" }}>
        <LoadingOutlined style={{ color: "#377fd8", fontSize: "30px" }} spin />
      </div>
      <Title level={5} style={{ marginTop: "10px" }}>
        Hold tight while we are proccessing order(s).
      </Title>
    </div>
  );
};

export default OrdersLoader;
