import PDFKit from "pdfkit";
import blobStream from "blob-stream";

import "../utils/register-pdfkit-files";
import AppLogo from "../assets/logo.0.png";

// import AppLogo from `../assets/logo.${}.png`.;

export interface InvoiceModel {
  id: number;
  by: string;
  credit: number;
  status: string;
  created: string;
  credited: boolean;
  tax: number | null;
  paidOn: string | null;
  paymentMethod: string | null;
  user: {
    role: string;
    username: string;
  };
}
/**
 * @param data label fields to be print
 * @description Generate pdf label for printing
 */
function printInvoice(doc: PDFKit.PDFDocument, data: InvoiceModel) {
  doc.addPage();

  // Header
  doc.image(AppLogo, {
    height: 40,
  });
  doc.moveUp(2);
  doc.text(new Date().toLocaleString(), { align: "right" });
  doc.lineCap("round").moveTo(50, 90).lineTo(545.28, 90).stroke();

  // Seperator
  doc
    .lineCap("round")
    .dash(2, {})
    .moveTo(50, 130)
    .lineTo(545.28, 130)
    .opacity(0.3)
    .stroke()
    .opacity(1);

  // Invoice Details
  doc.moveDown(2);
  doc.fontSize(18);
  doc.text("Invoice Details ");

  doc.moveDown();
  doc.fontSize(14);

  // Invoice ID
  doc.text("Invoice ID                : ");
  doc.moveUp(1);
  doc.text(data.id.toString(), { align: "right" });

  // Invoice created
  doc.text("Created                   : ");
  doc.moveUp(1);
  doc.text(new Date(data.created).toLocaleString(), { align: "right" });

  // Invoice By
  doc.text("Invoice By               : ");
  doc.moveUp(1);
  doc.text(data.by, { align: "right" });

  // Invoice Status
  doc.text("Status                     : ");
  doc.moveUp(1);
  doc.text(data.status, { align: "right" });

  // Invoice Status
  doc.text("Payment Method    : ");
  doc.moveUp(1);
  doc.text(data.paymentMethod ?? "-", { align: "right" });

  // Seperator
  doc
    .lineCap("round")
    .dash(2, {})
    .moveTo(50, 240)
    .lineTo(545.28, 240)
    .opacity(0.3)
    .stroke()
    .opacity(1);

  doc.moveDown(2);

  // Payment Details
  doc.fontSize(18);
  doc.text("Payment Details ");
  doc.fontSize(14);

  // Seperator
  doc
    .lineCap("round")
    .dash(2, {})
    .moveTo(50, 280)
    .lineTo(545.28, 280)
    .opacity(0.3)
    .stroke()
    .opacity(1);

  doc.moveDown();

  // Payment credit
  doc.text("Credit                     : ");
  doc.moveUp(1);
  doc.text(data.credit?.toString(), { align: "right" });

  // Payment Tax
  doc.text("Tax                         : ");
  doc.moveUp(1);
  doc.text(`+   ${data.tax?.toString() ?? "0"}`, { align: "right" });

  // Seperator
  doc
    .undash()
    .lineCap("round")
    .moveTo(350, 330)
    .lineTo(545.28, 330)
    .opacity(0.3)
    .stroke()
    .opacity(1);

  doc.moveDown();
  doc.text(`${data.credit + data.tax! ?? 0}`, { align: "right" });

  doc.moveDown(25.5);
  doc.text("Invoice by deviceforensic.com", { align: "center" });
}

export default (data: InvoiceModel[]) => {
  return new Promise<string>(async (resolve, reject) => {
    const doc = new PDFKit({
      margins: {
        top: 50,
        left: 50,
        right: 50,
        bottom: 50,
      },
      size: "A4",
      layout: "portrait",
      autoFirstPage: false,
    });

    const stream = doc.pipe(blobStream());

    stream.once("finish", function () {
      resolve(stream.toBlobURL("application/pdf"));
    });

    for (const d of data) {
      printInvoice(doc, d);
    }

    doc.end();
  });
};
