import React from "react";
import { Tabs } from "antd";
import { BarsOutlined, GlobalOutlined, GroupOutlined } from "@ant-design/icons";

import "./AdminServices.scss";
import Service from "./Services";
import Groups from "./Groups/ServiceGroups";
import GlobalActions from "./GlobalActions";

export interface ServicesProps {}

const { TabPane } = Tabs;

const Services: React.FunctionComponent<ServicesProps> = () => {
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane
          key="1"
          tab={
            <span>
              <BarsOutlined />
              Services
            </span>
          }
        >
          <Service />
        </TabPane>
        <TabPane
          key="2"
          tab={
            <span>
              <GroupOutlined />
              Groups
            </span>
          }
        >
          <Groups />
        </TabPane>
        <TabPane
          key="3"
          tab={
            <span>
              <GlobalOutlined />
              Global Actions
            </span>
          }
        >
          <GlobalActions />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Services;
