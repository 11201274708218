import {
  Col,
  Row,
  Form,
  Select,
  Switch,
  Button,
  message,
  Typography,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";

export interface InvoiceProps {
  username: string;
}

const { Title } = Typography;

const Invoice: React.FunctionComponent<InvoiceProps> = ({ username }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values: any) {
    values.username = username;
    setLoading(true);

    try {
      const { data } = await httpClient.post("payment/admin", values);

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Invoice created.");
      form.resetFields();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Create Invoice <FileTextOutlined />
      </Title>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{ instant: false, status: "Unpaid" }}
        onFinish={handleSubmit}
      >
        <Row gutter={[10, 10]}>
          <Col span={11}>
            <Form.Item
              name="credit"
              label="Credit"
              tooltip="Credit to add in invoice for user."
              rules={[{ required: true, message: "Credit is required." }]}
            >
              <InputNumber style={{ width: "100%" }} min={1} />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="status"
              label="Status"
              tooltip="Status of invoice."
              rules={[{ required: true, message: "Status is required." }]}
            >
              <Select>
                <Select.Option value="Paid">Paid</Select.Option>
                <Select.Option value="Unpaid">Unpaid</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              name="instant"
              label="Instant"
              valuePropName="instant"
              tooltip="Add credit to user instantly."
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Invoice
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Invoice;
