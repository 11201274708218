import {
  Badge,
  Input,
  Space,
  Table,
  Modal,
  Button,
  Tooltip,
  message,
  Pagination,
  Typography,
  Descriptions,
} from "antd";
import { truncate } from "lodash";
import { useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { SyncOutlined, UserAddOutlined } from "@ant-design/icons";

import "./SubUsers.scss";
import httpClient from "../../common/httpClient";
import CreateSubUserDialog from "./CreateDialog";
import { SubUserModel, subUserSubject } from "./subuser.utils";

const { Link } = Typography;

export interface SubUsersProps {}

const SubUsers: React.FunctionComponent<SubUsersProps> = () => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState();
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [subUsers, setSubUsers] = useState<any[]>([]);
  const [showCreate, setShowCreate] = useState(false);
  // const [cancelSeach, setCancelSearch] = useState<any>();
  const [editUser, setEditUser] = useState<SubUserModel>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    if (init) setInit(false);
    fetchSubusers();

    const unsub = subUserSubject.subscribe(async () => {
      await fetchSubusers();
    });

    return () => {
      unsub.unsubscribe();
    };
  }, [page, size]);

  /**
   * @description fetch subusers from remote api
   */
  async function fetchSubusers() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/subuser", {
        size,
        filter,
        page: page - 1,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      const _subUsers: any[] = data.subUsers.map((each: any, index: number) => {
        each["key"] = index;
        return each;
      });

      setTotal(data.total);
      setSubUsers(_subUsers);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  function handleEditUser(user: SubUserModel) {
    setEditUser(user);
    setShowCreate(true);
  }

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      render: (txt: string) => truncate(txt, { length: 8 }),
    },
    { title: "Username", dataIndex: "username", key: "username" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      key: "blocked",
      width: "100px",
      title: "Blocked",
      dataIndex: "blocked",
      render: (txt: boolean) => (txt ? "Yes" : "No"),
    },
    {
      key: "ips",
      width: "100px",
      dataIndex: "ips",
      title: "Allowd IPs",
      render: (txt: string[]) => txt.length,
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (txt: string) =>
        new Date(txt).toLocaleString("en-US", { timeZone: "America/Virgin" }),
    },
    {
      key: "modified",
      title: "Last Modified",
      dataIndex: "modified",
      render: (txt: string) =>
        new Date(txt).toLocaleString("en-US", { timeZone: "America/Virgin" }),
    },
    {
      key: "id",
      title: "Action",
      render: (txt: any, record: any) => (
        <Link onClick={() => handleEditUser(record)}>Edit</Link>
      ),
    },
  ];

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  return (
    <>
      <Button
        type="primary"
        icon={<UserAddOutlined />}
        onClick={() => {
          setEditUser(undefined);
          setShowCreate(true);
        }}
        style={{ width: "100%", marginBottom: "10px" }}
      >
        Add User
      </Button>

      <div
        style={
          !isMobile
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }
            : undefined
        }
      >
        <div style={{ width: isMobile ? "100%" : "50%" }}>
          <Descriptions
            bordered
            size="small"
            column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label={<Badge text="Total" color="blue" />}>
              {total}
            </Descriptions.Item>
          </Descriptions>
        </div>

        <div className="subuser_filter_wrapper">
          <Space>
            <Input
              allowClear
              size="small"
              value={filter}
              disabled={!!!total}
              placeholder="Search username"
              style={{ width: isMobile ? "100% !important" : "200px" }}
              onChange={(s) => setFilter(s.target.value as any)}
            />
            <div>
              <Button
                size="small"
                type="primary"
                onClick={fetchSubusers}
              >
                Filter
              </Button>
            </div>
            <div style={{ width: "100px" }}>
              <Tooltip title="Refresh">
                <Button
                  type="link"
                  loading={loading}
                  onClick={fetchSubusers}
                  icon={<SyncOutlined />}
                />
              </Tooltip>
            </div>
          </Space>
        </div>
      </div>

      <Table
        sticky
        size="small"
        loading={loading}
        columns={columns}
        pagination={false}
        tableLayout="auto"
        scroll={{ x: true }}
        dataSource={subUsers}
        className="sub_users_table"
      />
      <div className="pagination">
        <Pagination
          size="small"
          total={total}
          current={page}
          pageSize={size}
          showSizeChanger
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>

      <Modal
        centered
        footer={null}
        visible={showCreate}
        destroyOnClose
        title={editUser ? "Update Sub User" : "Create Sub User"}
        onCancel={() => {
          setShowCreate(false);
        }}
      >
        <CreateSubUserDialog
          show={showCreate}
          subUser={editUser}
          setShow={setShowCreate}
        />
      </Modal>
    </>
  );
};

export default SubUsers;
