import {
  Col,
  Row,
  Form,
  Input,
  Switch,
  Button,
  Divider,
  message,
  Skeleton,
  Typography,
  InputNumber,
} from "antd";
import { useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { MailOutlined, SettingOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";

export interface AdminSettingsProps {}

const { Title } = Typography;

const AdminSettings: React.FunctionComponent<AdminSettingsProps> = () => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState<any>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    getSettings();
  }, []);

  async function getSettings() {
    if (!!!store.getState().configs.sessionToken) return;
    try {
      const { data } = await httpClient.get("/settings");

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setInitValues(data);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  async function updateSettings(values: any) {
    setLoading(true);
    try {
      const { data } = await httpClient.post("/settings", values);

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      getSettings();
      message.success("Settings updated");
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Admin Settings <SettingOutlined />
      </Title>
      {initValues ? (
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={updateSettings}
          initialValues={initValues}
        >
          <div
            style={{
              overflowY: "auto",
              minHeight: "calc(100vh - 150px)",
              maxHeight: "calc(100vh - 150px)",
            }}
          >
            <Row>
              <Col span={isMobile ? 24 : 12}>
                <Row gutter={[10, 10]}>
                  <Col span={16}>
                    <Form.Item
                      name="adminEmail"
                      label="Admin Email"
                      tooltip="Admin email for sending admin notifications."
                      rules={[
                        { type: "email", message: "Invalid email." },
                        { required: true, message: "Admin email is required." },
                      ]}
                    >
                      <Input placeholder="admin@yourdomain.com" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="approved"
                      valuePropName="checked"
                      label="Pre-Approve new users"
                      tooltip="If checked then all new signup users will be pre approved."
                      rules={[
                        { type: "boolean", message: "Invalid approve value." },
                      ]}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[10, 10]}>
                  <Col span={8}>
                    <Form.Item
                      name="coinbaseTax"
                      label="Coinbase Tax"
                      tooltip="Coinbase Tax."
                      rules={[
                        {
                          required: true,
                          message: "Coinbase tax is required.",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="paypalTax"
                      label="Paypal Tax"
                      tooltip="Paypal Tax."
                      rules={[
                        { required: true, message: "Paypal tax is required." },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="stripeTax"
                      label="Stripe Tax"
                      tooltip="Stripe Tax."
                      rules={[
                        { required: true, message: "Stripe tax is required." },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} min={0} />
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                  <Button
                    size="large"
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Update
                  </Button>
                </div>

                <Divider />
                <Title level={4}>
                  Email Templates <MailOutlined />
                </Title>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="welcomeTemplate"
                      label="Welcome Email Template"
                      tooltip="Welcome email is sent when user signup."
                      rules={[
                        {
                          required: true,
                          message: "Welcome email template is required.",
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="<h1>Welcome Template</h1>" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="passwordResetTemplate"
                      label="Password Reset Email Template"
                      tooltip="Password Reset email is sent when user password is reset."
                      rules={[
                        {
                          required: true,
                          message: "Password reset email template is required.",
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="<h1>Password Reset Template</h1>" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="orderCompletedTemplate"
                      label="Order Complete Email Template"
                      tooltip="Order Complete email is sent when user password is reset."
                      rules={[
                        {
                          required: true,
                          message: "Order complete email template is required.",
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="<h1>Order complete Template</h1>" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="placedManualOrderTemplate"
                      label="Manual Order placed Email Template"
                      tooltip="Manual order email is sent to admin when client place manual order."
                      rules={[
                        {
                          required: true,
                          message:
                            "Manual Order placed email template is required.",
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="<h1>Manual Order Template</h1>" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="orderBatchCompleteTemplate"
                      label="Order Batch Completed Email Template"
                      tooltip="Order Batch email is sent when batch is completed."
                      rules={[
                        {
                          required: true,
                          message:
                            "Order Batch completed email template is required.",
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="<h1>Order Batch Completed Template</h1>" />
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                  <Button
                    size="large"
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};

export default AdminSettings;
