import { BehaviorSubject } from "rxjs";

export interface UserInfoModel {
  id: string;
  email: string;
  ips: string[];
  credit: number;
  created: string;
  username: string;
  modified: string;
  blocked: boolean;
  approved: boolean;
  unlinked: boolean;
  promoCode: string;
  commission: number;
  rights: UserRightsModel;
  priceGroup: PriceGroupModel;
  role: "Admin" | "Main" | "Sub";
  allowedPaymentMethods: ["Coinbase", "Stripe", "Paypal"];
}

export interface PriceGroupModel {
  id: number;
  title: string;
  discount: number;
  deleted: boolean;
}

export interface UserRightsModel {
  history: boolean;
  addFunds: boolean;
  viewBalance: boolean;
  servicePrice: boolean;
}

export interface UserInfoForm {
  ips: string[];
  email: string;
  credit: number;
  username: string;
  rights: string[];
  unlinked: boolean;
  blocked: boolean;
  approved: boolean;
  commission: number;
  priceGroup: number;
  allowedPaymentMethods: string[];
}

export const initValues = (init: UserInfoModel): UserInfoForm => {
  const _rights = [];
  const { history, servicePrice, viewBalance } = init.rights ?? {
    addFunds: true,
    history: true,
    servicePrice: true,
    viewBalance: true,
  };

  if (history) _rights.push("history");
  // if (addFunds) _rights.push("addFunds");
  if (viewBalance) _rights.push("viewBalance");
  if (servicePrice) _rights.push("servicePrice");

  return {
    ips: init.ips ?? [],
    rights: _rights,
    email: init.email,
    credit: init.credit,
    blocked: init.blocked,
    unlinked: init.unlinked,
    approved: init.approved,
    username: init.username,
    commission: init.commission,
    priceGroup: init.priceGroup ? init.priceGroup.id : 0,
    allowedPaymentMethods: init.allowedPaymentMethods,
  };
};

export interface ServiceModel {
  id: number;
  time: string;
  title: string;
  price: number;
  manual: boolean;
  featured: boolean;
  disabled: boolean;
  description: string;
  group: ServiceGroupModel;
}

export interface ServiceGroupModel {
  id: number;
  title: string;
}

export const usersListSubject = new BehaviorSubject(null);
export const discoutnServiceSubject = new BehaviorSubject(null);
export const disabledServiceSubject = new BehaviorSubject(null);
