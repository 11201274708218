import React, { useState } from "react";
import { Col, Row, Form, Select, Button, message, InputNumber } from "antd";

import httpClient from "../../../../common/httpClient";
// import SelService from "../../../../common/Components/SelectService";

export interface ServicesPriceUpdateProps {
  groupId: number;
  setShow: (show: boolean) => void;
}

const ServicesPriceUpdate: React.FunctionComponent<ServicesPriceUpdateProps> =
  ({ groupId, setShow }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    // const [service, setService] = useState<any>();

    async function handleSubmit(values: any) {
      setLoading(true);

      try {
        const { data } = await httpClient.post(
          "/service/update-service-group",
          {
            ...values,
            groupId,
          }
        );

        if (data.statusCode === 500 || data.error) {
          switch (typeof data.message) {
            case "object":
              data.message.forEach((err: string) => message.error(err));
              return;
            default:
              message.error(data.message);
              return;
          }
        }

        setShow(false);
      } catch (err) {
        message.error("Something went wrong.");
      } finally {
        setLoading(false);
      }
    }

    return (
      <>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                name="percent"
                label="Percent"
                tooltip="Group title"
                rules={[
                  { required: true, message: "Percentage is required." },
                  //   { min: 0.01, max: 99.9, message: "Min percentage 0.01" },
                ]}
              >
                <InputNumber min={0.01} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="action"
                label="Operator"
                tooltip="Group title"
                rules={[{ required: true, message: "Operator is required." }]}
              >
                <Select>
                  <Select.Option value="increase">+</Select.Option>
                  <Select.Option value="decrease">-</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div style={{ textAlign: "right" }}>
            <Button htmlType="submit" type="primary" loading={loading}>
              Update Price
            </Button>
          </div>
        </Form>
      </>
    );
  };

export default ServicesPriceUpdate;
