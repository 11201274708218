import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Switch,
  message,
  Typography,
  InputNumber,
} from "antd";
import { sortBy } from "lodash";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  ServiecForm,
  ServiceModel,
  initValues as initFormValues,
} from "./admin.utils";
import httpClient from "../../../common/httpClient";
import CustomProviderSelect from "./SelectProviderService";

export interface ServiceActionProps {
  refresh: () => void;
  editService?: ServiceModel;
  setShow: (show: boolean) => void;
}

const { Text } = Typography;

const ServiceAction: React.FunctionComponent<ServiceActionProps> = ({
  refresh,
  setShow,
  editService,
}) => {
  const initValues = initFormValues(editService);
  const [form] = Form.useForm<ServiecForm>();
  const [groups, setGroups] = useState<any[]>(
    editService ? [editService.group] : []
  );
  const [manual, setManual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clearSearchInt, setClearSearchInt] = useState<NodeJS.Timeout>();

  async function handleSubmit(values: ServiecForm) {
    setLoading(true);

    if (values.manual) {
      values.selSvc = undefined;
    } else {
      values.selSvc = values.selSvc?.map((ser, index) => {
        ser.priority = index;
        return ser;
      });
    }

    try {
      let res: AxiosResponse;
      if (!!editService) {
        res = await httpClient.patch("/service", {
          ...values,
          id: editService!.id,
        });
      } else {
        res = await httpClient.post("/service", { ...values });
      }

      const { data } = res;

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      refresh();
      setShow(false);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description fetch groups
   * @param search search string
   */
  async function fetchGroups(search?: string) {
    try {
      const { data } = await httpClient.get("/service/group", {
        page: 0,
        size: 10,
        filter: search,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setGroups(data.groups);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  /**
   * @description search handler
   * @param search search string
   */
  function searchHandler(search: string) {
    if (clearSearchInt) clearTimeout(clearSearchInt);

    const timeOut = setTimeout(() => {
      fetchGroups(search);
    }, 500);

    setClearSearchInt(timeOut);
  }

  function handleFormChange(_: any, values: ServiecForm) {
    const { manual } = values;
    setManual(manual);
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
        initialValues={initValues}
        onValuesChange={handleFormChange}
      >
        <Row gutter={[10, 10]} style={{ marginBottom: "-10px" }}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              tooltip="Service title"
              rules={[
                { min: 5, message: "Min 5 characters." },
                { max: 50, message: "Max 50 characters." },
                { required: true, message: "Service title is required." },
              ]}
            >
              <Input placeholder="Apple check" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="price"
              label="Price"
              tooltip="Service price"
              rules={[
                { required: true, message: "Service price is required." },
              ]}
            >
              <InputNumber
                min={0.01}
                placeholder="0.2"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="limit"
              label="Limit"
              tooltip="Service limit"
            >
              <InputNumber
                min={0.01}
                style={{ width: "100%" }}
                placeholder="Default: 5k"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]} style={{ marginBottom: "-10px" }}>
          <Col span={18}>
            <Form.Item
              name="groupId"
              label="Service group"
              tooltip="Service group"
              rules={[
                { required: true, message: "Service group is required." },
              ]}
            >
              <Select
                showSearch
                showArrow={false}
                filterOption={false}
                onSearch={searchHandler}
                placeholder="Type to search"
                notFoundContent="No Groups Found."
              >
                {groups.map((each: any) => (
                  <Select.Option key={each.id} value={each.id}>
                    {each.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="time"
              label="Time"
              tooltip="Time this service required to give results."
              rules={[{ required: true, message: "Time required" }]}
            >
              <Input placeholder="1 hour" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Form.Item
              name="disabled"
              label="Force Disable"
              valuePropName="checked"
              tooltip="Disable service globally."
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="featured"
              label="Featured"
              valuePropName="checked"
              tooltip="Mark service as featured."
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="manual"
              label="Manual"
              valuePropName="checked"
              tooltip="Make service manual."
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]} style={{ marginBottom: "-10px" }}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              tooltip="Service description."
              rules={[{ required: false }]}
            >
              <Input.TextArea
                placeholder="Apple check service."
                style={{ minHeight: "100px", maxHeight: "100px" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {!manual && (
            <Col span={24}>
              <div>
                <Text>Provider Services</Text>
              </div>
              <div style={{ padding: "5px" }} />
              <Form.List
                name="selSvc"
                initialValue={sortBy(initValues.selSvc, ["priority"])}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <div className="provider_service_list">
                      {fields.map((field, index) => (
                        <Form.Item required key={field.key}>
                          <Row>
                            <Col span={fields.length > 1 ? 22 : 24}>
                              <CustomProviderSelect
                                field={field}
                                selProvider={editService?.selSvc.find(
                                  (v) => v.priority === index
                                )}
                              />
                            </Col>
                            {fields.length > 1 && (
                              <Col span={2} style={{ textAlign: "right" }}>
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              </Col>
                            )}
                          </Row>
                        </Form.Item>
                      ))}
                    </div>
                    <Form.Item>
                      <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        style={{ width: "100%" }}
                        onClick={() => {
                          add("", fields.length);
                        }}
                      >
                        Add Provider Service
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          )}
        </Row>

        <div style={{ textAlign: "right" }}>
          <Button loading={loading} type="primary" htmlType="submit">
            {!!editService ? "Save" : "Create"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ServiceAction;
