import React from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";

import "./Preferences.scss";
import Apikeys from "../../Components/Preferences/ApiKeys";
import { UserDetails } from "../../actions/userdetails.action";
import PasswordReset from "../../Components/Preferences/PasswordUpdate";

export interface PreferencesProps {
  userDetails: UserDetails;
}

const Preferences: React.FunctionComponent<PreferencesProps> = ({
  userDetails,
}) => {

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  
  return (
    <>
      <div>
        <Row>
          <Col span={isMobile ? 24 : 10}>
            <PasswordReset />
          </Col>
          <Col span={14} />
        </Row>

        <Row>
          <Col span={24}>
            <Apikeys />
          </Col>
        </Row>
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  const userDetails: UserDetails = state.userDetails;
  return {
    userDetails,
  };
}

export default connect(mapStateToProps)(Preferences);
