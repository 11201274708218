import React from "react";
import { Tabs } from "antd";

import "./Users.scss";
import UserList from "./UserList";
import PriceGroups from "./PriceGroups";
import { UserOutlined, DollarOutlined } from "@ant-design/icons";

export interface UsersProps {}

const { TabPane } = Tabs;

const Users: React.FunctionComponent<UsersProps> = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <UserOutlined />
            Users
          </span>
        }
        key="1"
      >
        <UserList />
      </TabPane>
      <TabPane
        tab={
          <span>
            <DollarOutlined />
            Price Groups
          </span>
        }
        key="2"
      >
        <PriceGroups />
      </TabPane>
      {/* <TabPane
        tab={
          <span>
            <DollarOutlined />
            Bulk Action
          </span>
        }
        key="3"
      >
        <BulkActions />
      </TabPane> */}
    </Tabs>
  );
};

export default Users;
