import React from "react";

import BatchHistory from "../../Batch/BatchHistory";

export interface BatchHistoryAdminProps {}

const BatchHistoryAdmin: React.FunctionComponent<BatchHistoryAdminProps> =
  () => {
    return <BatchHistory admin />;
  };

export default BatchHistoryAdmin;
