export const rules = {
  batchName: [
    { max: 20, message: "Maximum 20 characters." },
    { type: "string", message: "Invalid Batch name." },
    { required: true, message: "Batch name required." },
  ],
  service: [
    // { type: "number", message: "Invalid Service." },
    // { required: true, message: "Service is required." },
  ],
  imeis: [
    { required: true, message: "IMEI(s) required." },
    {
      type: "string",
      message: "Invalid value provided.",
    },
  ],
  notify: [
    { required: true, message: "required." },
    { type: "boolean", message: "Invalid value." },
  ],
};

export const initVaules = {
  imeis: "",
  notify: true,
  service: undefined,
  batch: `Batch - ${new Date().toLocaleDateString()}`,
};

export interface OrderFormValues {
  batch: string;
  imeis: string;
  notify: boolean;
  service: number;
}
