import {
  Col,
  Row,
  Form,
  Steps,
  Input,
  Select,
  Button,
  message,
  Typography,
  InputNumber,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { BankOutlined, CreditCardOutlined } from "@ant-design/icons";

import "./Payment.scss";
import httpClient from "../../common/httpClient";
import StripePay from "../../Components/Payment/StripePay";
import { UserDetails } from "../../actions/userdetails.action";

const { Step } = Steps;
const { Link, Title } = Typography;

export interface PaymentForm {
  credit?: number;
  stripeEmail?: string;
  paymentMethod: "Stripe" | "Paypal" | "Coinbase";
}

export interface PaymentProps {
  invoiceId?: number;
  closeDialog: () => void;
  userDetails?: UserDetails;
}

const Payment: React.FunctionComponent<PaymentProps> = ({
  invoiceId,
  closeDialog,
  userDetails,
}) => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm<PaymentForm>();
  const [loading, setLoading] = useState(false);
  const [approveId, setApproveId] = useState("");
  const [stripePay, setStripePay] = useState(false);
  const [searchCountry, setSearchCountry] = useState("");
  const [countries, setCountries] = useState<
    { name: string; flag: string; code: string; alpha2: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      let _countries = await axios.get(
        "https://restcountries.com/v3.1/all?fields=name,idd,flag,cca2"
      );
      _countries = _countries.data.map((c: any) => {
        const codeSuffix = Object.values(c.idd.suffixes);
        return {
          alpha2: c.cca2,
          flag: c.flag,
          name: c.name.common,
          code:
            codeSuffix.length > 1 ? c.idd.root : c.idd.root + codeSuffix.join(),
        };
      });
      setCountries(_countries as any);
    })();
  }, []);

  const stripePromise = loadStripe(
    // "pk_test_51Hc8C7H5hMuoopv28e5IlZisx1G6rt82n3bdWZ4kUsIIQdyvCpkdr3mEcxyWDwq7sEHFPTlMEk38XUf7ooLPC9UA00fCkbI51U"
    "pk_live_51Hc8C7H5hMuoopv2w8Dlp6N9IxMuHzP9H2BRXSErDV7w4Q9FBon9bOjFkZZEqZoQ7hA2FaeeXyh7SaL6IiQxSf2s00T4csj86J"
  );

  async function handleSubmit(values: PaymentForm) {
    setLoading(true);
    if (invoiceId) {
      await handlePayInvoice(values);
    } else {
      await handleCreditPurchase(values);
    }
    setLoading(false);
  }

  async function handleCreditPurchase(values: PaymentForm) {
    const { data } = await httpClient.post("/payment", values);

    if (data.statusCode === 500 || data.error) {
      switch (typeof data.message) {
        case "object":
          data.message.forEach((err: string) => message.error(err));
          return;
        default:
          message.error(data.message);
          return;
      }
    }

    setCurrent(1);
    setApproveId(data.approve);
  }

  async function handlePayInvoice(values: PaymentForm) {
    const { data } = await httpClient.post("/payment/pay-invoice", {
      invoiceNumber: invoiceId,
      paymentMethod: values.paymentMethod,
    });

    if (data.statusCode === 500 || data.error) {
      switch (typeof data.message) {
        case "object":
          data.message.forEach((err: string) => message.error(err));
          return;
        default:
          message.error(data.message);
          return;
      }
    }

    setCurrent(1);
    setApproveId(data.approve);
  }

  function handleFormChange(_: any, values: PaymentForm) {
    if (values.paymentMethod === "Stripe") {
      setStripePay(true);
      return;
    }

    setStripePay(false);
  }

  return (
    <>
      <Steps current={current}>
        <Step title="Credit" icon={<CreditCardOutlined />} />
        <Step title="Pay Now" icon={<BankOutlined />} />
      </Steps>

      <div>
        {current === 0 ? (
          <div>
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              onFinish={handleSubmit}
              initialValues={{ credit: 10 }}
              onValuesChange={handleFormChange}
            >
              <Row gutter={[10, 10]}>
                {!invoiceId && (
                  <Col span={8}>
                    <Form.Item
                      name="credit"
                      label="Credit"
                      tooltip="Enter Credit amount you want to purchase."
                      rules={[
                        { type: "number", message: "Invalid credit provided." },
                        {
                          required: !!invoiceId,
                          message: "Credit is required.",
                        },
                      ]}
                    >
                      <InputNumber
                        min={10}
                        style={{ width: "100%" }}
                        placeholder="Enter credit."
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={invoiceId ? 24 : 16}>
                  <Form.Item
                    name="paymentMethod"
                    label="Payment Method"
                    tooltip="Select payment method of choice."
                    rules={[
                      {
                        required: true,
                        message: "Payment method is required.",
                      },
                    ]}
                  >
                    <Select allowClear placeholder="Select Payment Method.">
                      <Select.Option
                        disabled={
                          !userDetails!.allowedPaymentMethods.includes("Paypal")
                        }
                        value="Paypal"
                      >
                        Paypal
                      </Select.Option>
                      <Select.Option
                        disabled={
                          !userDetails!.allowedPaymentMethods.includes("Stripe")
                        }
                        value="Stripe"
                      >
                        Stripe
                      </Select.Option>
                      <Select.Option
                        disabled={
                          !userDetails!.allowedPaymentMethods.includes(
                            "Coinbase"
                          )
                        }
                        value="Coinbase"
                      >
                        Coinbase
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {form.getFieldValue("paymentMethod") === "Stripe" && (
                <>
                  <Row justify="space-between" gutter={12}>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label="Full Name"
                        style={{ width: "100%" }}
                        tooltip="Full Name is required."
                        rules={[
                          { type: "string", message: "Invalid name provided." },
                          {
                            required: true,
                            message: "Name is required.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Full Name."
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="country"
                        label="Country"
                        style={{ width: "100%" }}
                        tooltip="Full Name is required."
                        rules={[
                          { type: "string", message: "Invalid name provided." },
                          {
                            required: true,
                            message: "Name is required.",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          placeholder="Country"
                          filterOption={false}
                          onSearch={(s) => setSearchCountry(s)}
                        >
                          {countries
                            .filter((s) =>
                              s.name
                                .toUpperCase()
                                .startsWith(searchCountry.toUpperCase())
                            )
                            .map((c) => (
                              <Select.Option value={c.alpha2}>
                                {c.flag + " " + c.name}
                              </Select.Option>
                            ))}
                        </Select>
                        {/* <Input
                          placeholder="Full Name."
                          style={{ width: "100%" }}
                        /> */}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Item
                      name="address"
                      label="Address"
                      style={{ width: "100%" }}
                      tooltip="Address is required."
                      rules={[
                        {
                          type: "string",
                          message: "Invalid address provided.",
                        },
                        {
                          required: true,
                          message: "Address is required.",
                        },
                      ]}
                    >
                      <Input placeholder="Address." style={{ width: "100%" }} />
                    </Form.Item>
                  </Row>
                </>
              )}

              <div style={{ textAlign: "right" }}>
                <Button loading={loading} type="primary" htmlType="submit">
                  Purchase
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div>
            {stripePay && approveId ? (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: approveId }}
              >
                <StripePay closeDialog={closeDialog} />
              </Elements>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Title level={5}>This link is valid for next 24 hours.</Title>
                <Link href={approveId} target="_blank">
                  Click Here To Pay Now
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  const userDetails: UserDetails = state.userDetails;
  return {
    userDetails,
  };
}

export default connect(mapStateToProps, null)(Payment);
